import React from 'react';
import { List } from 'antd';

import OMAlertEvent from '../../../../utils/Event/OMAlertEvent';

import './OccurringMeasurementPoint.css';

interface OccurringMeasurementPointProps {
  occurringMeasurementPoints: OMAlertEvent[];
}

/**
 * 警報発生中の計測ポイントコンポーネント
 * @param {OccurringMeasurementPointProps} props
 * @returns 警報発生中の計測ポイント
 */
const OccurringMeasurementPoint: React.FC<OccurringMeasurementPointProps> = (props: OccurringMeasurementPointProps) => {
  const { occurringMeasurementPoints } = props;

  return (
    <>
      {!!occurringMeasurementPoints.length && (
        <div>
          <div className="om-dashboard-alert-occurrence-bar">
            <b className="om-dashboard-alert-occurrence-text">警報発生中！</b>
          </div>
          <div className="om-dashboard-alert-occurring-items">
            <List
              grid={{ column: 4 }}
              dataSource={occurringMeasurementPoints}
              renderItem={(item) => (
                <List.Item style={{ textAlign: 'center', margin: '3px 0' }}>
                  <span
                    className={`om-dashboard-alert-occurrence-${item.severity === 1 ? 'red' : 'yellow'}-style-circle`}
                    style={{ marginRight: '3px', fontSize: '17px', fontFamily: 'メイリオ' }}
                  >
                    ●
                  </span>
                  {item.monitorName}
                </List.Item>
              )}
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default OccurringMeasurementPoint;
