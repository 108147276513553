import React, {
  ChangeEvent,
  useState,
  useEffect,
} from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { SelectValue } from 'antd/lib/select';
import { ColumnFilterItem, ColumnProps } from 'antd/lib/table';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import {
  FilterIcon,
  TextFilter,
  FilterDropdown,
  TableFilter,
} from './FilterComponent';
import {
  ERROR_NO_AUTH_MESSAGE,
  ERROR_NOTIFICATION_NOT_EXISTS,
  ERROR_NOTIFICATION_UPDATE,
  SUCCESS_NOTIFICATION_CREATED,
  SUCCESS_NOTIFICATION_UPDATED,
  VALIDATE_ERROR_NOTIFICATION_GROUP_REQUIRED,
  VALIDATE_ERROR_NOTIFICATION_NAME_REQUIRED,
  VALIDATE_ERROR_NOTIFICATION_SITE_REQUIRED,
  VALIDATE_ERROR_NOTIFICATION_TYPE_REQUIRED,
} from '../../../utils/messages';
import { AUTHENTICATION_TYPE_MATRIX, containsUIAuthType } from '../../../utils/common/Authentication';
import EmailGroup from '../../../utils/DynamoDB/EmailGroup';
import EmailNotificationGroup, { SendParameter } from '../../../utils/DynamoDB/EmailNotificationGroup';
import EmailNotificationTargetSiteInfo from '../../../utils/Event/EmailNotificationTargetSiteInfo';

import './EmailNotificationGroupSettingsModal.css';

const { Title } = Typography;
const CheckboxGroup = Checkbox.Group;
const SelectOption = Select.Option;

interface EmailNotificationGroupSettingsModalProps {
  visible: boolean;
  onClose: () => void;
  targetInfo: EmailNotificationGroup;
  siteInformationListForModal: EmailNotificationTargetSiteInfo[];
  refreshParentTable: () => void;
}

interface EmailGroupInfo {
  id: string;
  groupName: string;
}

/**
 * ソートして返却
 * @param {string} a 比較対象A
 * @param {string} b 比較対象B
 * @returns {number} ソートの判定結果
 */
const getSortedItems = (a: string, b: string) => {
  const first = [a, b].sort()[0];
  return first === a ? -1 : 1;
};

/**
 * 数値をソートして返却
 * @param {number} a 比較対象A
 * @param {number} b 比較対象B
 * @returns {number} ソートの判定結果
 */
const getSortedNumber = (a: number, b: number): number => {
  if (Number.isNaN(a)) return -1;
  if (Number.isNaN(b)) return 1;
  return a - b;
};

/**
 * 発電・死活監視メール通知設定モーダル画面（追加/編集）
 * @param {StatusSelectProps} props プロパティ
 * @returns 発電・死活監視メール通知設定モーダル画面（追加/編集）
 */
const EmailNotificationGroupSettingsModal: React.FC<EmailNotificationGroupSettingsModalProps> = (props: EmailNotificationGroupSettingsModalProps) => {
  const {
    visible,
    onClose,
    targetInfo,
    siteInformationListForModal,
    refreshParentTable,
  } = props;

  const [editedNotificationGroupName, setEditedNotificationGroupName] = useState<string>('');
  const [emailGroups, setEmailGroups] = useState<EmailGroupInfo[]>([]);
  const [editTargetGroups, setEditTargetGroups] = useState<string[]>([]);
  const [editMailTypes, setEditMailTypes] = useState<string[]>([]);
  const [selectedSites, setSelectedSites] = useState<EmailNotificationTargetSiteInfo[]>([]);
  const [siteInformationList, setSiteInformationList] = useState<EmailNotificationTargetSiteInfo[]>([]);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!visible) return () => { /* 何もしない */ };

    let canceled = false;
    (async () => {
      setLoading(true);
      setButtonDisabled(false);

      // 権限によるボタン表示制御
      const {
        SOLAR_MENU: {
          EMAIL_NOTIFICATION_SETTINGS: {
            SOLAR_EMAIL_NOTIFICATION_SETTINGS_UPDATE: EMAIL_NOTIFICATION_GROUP_UPDATE,
          },
        },
      } = AUTHENTICATION_TYPE_MATRIX;

      const registerSites = siteInformationListForModal.filter((site) => targetInfo.targetSites.includes(site.externalId));

      // DynamoDBから通知グループを取得
      const allEmailGroups: EmailGroupInfo[] = await EmailGroup.getAllGroups()
        .then((groups) => (
          [...groups].sort((a, b) => a.groupName.localeCompare(b.groupName))
            .map(({ id, groupName }) => ({ id, groupName }))
        ));

      // 通知グループを集約(削除されている通知グループは表示しない)
      const targetGroupIdsFilter = targetInfo.targetGroupIds.filter((target) => allEmailGroups.some((emailGroup) => emailGroup.id === target));

      if (!canceled) {
        // 権限制御によるボタン制御
        setButtonDisabled(!await containsUIAuthType(EMAIL_NOTIFICATION_GROUP_UPDATE));

        // 親画面からの引数を反映
        setEditedNotificationGroupName(targetInfo.notificationGroup);

        // 通知メールの選択状態をチェックボックスに反映
        setEditMailTypes(targetInfo.emailTypes);

        // 通知グループをコンボボックスに反映
        setEmailGroups(allEmailGroups);

        // 通知グループの選択状態を反映
        setEditTargetGroups(targetGroupIdsFilter);

        // CDFから取得したサイト情報を一覧に反映
        setSiteInformationList(siteInformationListForModal);

        // サイト選択情報をチェックボックスに反映
        setSelectedSites(registerSites);
      }
      setLoading(false);
    })();
    return () => { canceled = true; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  /**
   * 通知グループの項目定義
   */
  const targetGroupOptions = emailGroups.map(({ id, groupName }) => (
    <SelectOption key={id}>{groupName}</SelectOption>
  ));

  /**
   * 通知グループ選択時のイベントハンドラ
   * @param {SelectValue} selectedEmailGroups 選択項目
   */
  const handleChangeTargetGroups = (selectedEmailGroups: SelectValue): void => {
    setEditTargetGroups(selectedEmailGroups as string[]);
  };

  /**
   * 分類名変更時のイベントハンドラ
   * @param {React.ChangeEvent<HTMLInputElement>} e 分類名
   */
  const handleChangeNotificationGroup = (e: ChangeEvent<HTMLInputElement>): void => {
    setEditedNotificationGroupName(e.currentTarget.value);
  };

  /**
   * 通知メール選択時のイベントハンドラ
   * @param {CheckboxValueType[]} checkedValues 選択項目
   */
  const handleChangeEmailTypes = (checkedValues: CheckboxValueType[]): void => {
    setEditMailTypes(checkedValues as string[]);
  };

  /**
   * チェックボックスクリック時のイベントハンドラ
   * @param {EmailNotificationTargetSiteInfo[]} selectedRows 選択項目
   */
  const handleChangeSelectedSites = (selectedRows: EmailNotificationTargetSiteInfo[]): void => {
    setSelectedSites(selectedRows);
  };

  /**
   * モーダル画面クローズ時のイベントハンドラ
   */
  const handleCloseModal = () => {
    onClose();

    // 次回のモーダル画面表示時に編集内容が残ってしまうため初期化する
    setEditedNotificationGroupName('');
    setEditTargetGroups([]);
    setEditMailTypes([]);
    setSelectedSites([]);
    setSiteInformationList([]);
  };

  /**
   * チェックボックスの挙動制御
   */
  const rowSelection = {
    onChange: (_: string[] | number[], selectedRows: EmailNotificationTargetSiteInfo[]) => {
      handleChangeSelectedSites(selectedRows);
    },
    selectedRowKeys: selectedSites.map(({ externalId }) => externalId),
  };

  /**
   * 登録・更新ボタンクリック時のイベントハンドラ
   */
  const handleAddMailSetting = async () => {
    if (!editedNotificationGroupName.length) {
      message.error(VALIDATE_ERROR_NOTIFICATION_NAME_REQUIRED);
      return;
    }

    if (!editTargetGroups.length) {
      message.error(VALIDATE_ERROR_NOTIFICATION_GROUP_REQUIRED);
      return;
    }

    if (!editMailTypes.length) {
      message.error(VALIDATE_ERROR_NOTIFICATION_TYPE_REQUIRED);
      return;
    }

    if (!rowSelection.selectedRowKeys?.length) {
      message.error(VALIDATE_ERROR_NOTIFICATION_SITE_REQUIRED);
      return;
    }
    setLoading(true);

    const sendParameter: SendParameter = {
      oldNotificationGroupName: targetInfo.notificationGroup,
      editedNotificationGroupName,
      targetGroupIds: editTargetGroups,
      emailTypes: editMailTypes,
      targetSites: rowSelection.selectedRowKeys,
      created: targetInfo.created,
      lastUpdated: targetInfo.lastUpdated,
    };

    try {
      if (!targetInfo.notificationGroup) {
        await EmailNotificationGroup.createNotificationGroup(sendParameter);
        message.success(SUCCESS_NOTIFICATION_CREATED);
      } else {
        await EmailNotificationGroup.updateNotificationGroup(sendParameter);
        message.success(SUCCESS_NOTIFICATION_UPDATED);
      }
      refreshParentTable();
      handleCloseModal();
    } catch (exception) {
      if (String(exception) === 'Error: 404') {
        message.error(ERROR_NOTIFICATION_NOT_EXISTS);
      } else {
        message.error(ERROR_NOTIFICATION_UPDATE);
      }
      setLoading(false);
    }
  };

  const columns: ColumnProps<EmailNotificationTargetSiteInfo>[] = [
    {
      title: '発電所名',
      dataIndex: 'siteName',
      width: 375,
      fixed: 'left',
      ellipsis: true,
      filterIcon: FilterIcon,
      filterDropdown: !!siteInformationList && TextFilter,
      onFilter: (value, result) => (result.siteName.toLowerCase().includes((value as string).toLowerCase())),
      sorter: (a, b) => getSortedItems(a.siteName, b.siteName),
      render: (_, item: EmailNotificationTargetSiteInfo) => item.siteName,
    },
    {
      title: '事業',
      dataIndex: 'businessName',
      width: 300,
      ellipsis: true,
      filterIcon: FilterIcon,
      filterDropdown: !!siteInformationList && TextFilter,
      onFilter: (value, result) => (result.businessName.toLowerCase().includes((value as string).toLowerCase())),
      sorter: (a, b) => getSortedItems(a.businessName, b.businessName),
      render: (_, item: EmailNotificationTargetSiteInfo) => item.businessName,
    },
    {
      title: '第１カテゴリ',
      dataIndex: 'category1',
      width: 300,
      filterIcon: FilterIcon,
      filterDropdown: !!siteInformationList && TextFilter,
      onFilter: (value, result) => (result.category1.toLowerCase().includes((value as string).toLowerCase())),
      sorter: (a, b) => getSortedItems(a.category1, b.category1),
      render: (_, item: EmailNotificationTargetSiteInfo) => item.category1,
    },
    {
      title: '第２カテゴリ',
      dataIndex: 'category2',
      width: 300,
      ellipsis: true,
      filterIcon: FilterIcon,
      filterDropdown: !!siteInformationList && TextFilter,
      onFilter: (value, result) => (result.category2.toLowerCase().includes((value as string).toLowerCase())),
      sorter: (a, b) => getSortedItems(a.category2, b.category2),
      render: (_, item: EmailNotificationTargetSiteInfo) => item.category2,
    },
    {
      title: '運転開始日',
      dataIndex: 'startDate',
      width: 150,
      ellipsis: true,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string>(siteInformationList.map(({ startDate }) => startDate));
        return TableFilter(uniqueData);
      })(),
      filterDropdown: !!siteInformationList && FilterDropdown,
      onFilter: (value, result) => result.startDate === value,
      sorter: (a, b) => getSortedItems(a.startDate, b.startDate),
      render: (_, item: EmailNotificationTargetSiteInfo) => item.startDate,
    },
    {
      title: '連系容量(kW)',
      dataIndex: 'connectCapacity',
      width: 300,
      ellipsis: true,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string>(
          siteInformationList.map(({ connectCapacity }) => (Number.isNaN(connectCapacity) ? '-' : String(connectCapacity))),
        );
        return TableFilter(uniqueData);
      })(),
      filterDropdown: !!siteInformationList && FilterDropdown,
      // フィルタ処理と画面表示の際にconnectCapacityの値と型を揃える
      onFilter: (value, result) => {
        const connectCapacity = Number.isNaN(result.connectCapacity) ? '-' : String(result.connectCapacity);
        return connectCapacity === String(value);
      },
      sorter: (a, b) => getSortedNumber(a.connectCapacity, b.connectCapacity),
      render: (_, item: EmailNotificationTargetSiteInfo) => (Number.isNaN(item.connectCapacity) ? '-' : item.connectCapacity),
    },
    {
      title: 'パネル容量',
      dataIndex: 'panelCapacity',
      width: 300,
      ellipsis: true,
      filters: ((): ColumnFilterItem[] => {
        const uniqueData = new Set<string>(
          siteInformationList.map(({ panelCapacity }) => (Number.isNaN(panelCapacity) ? '-' : String(panelCapacity))),
        );
        return TableFilter(uniqueData);
      })(),
      filterDropdown: !!siteInformationList && FilterDropdown,
      // フィルタ処理と画面表示の際にpanelCapacityの値と型を揃える
      onFilter: (value, result) => {
        const panelCapacity = Number.isNaN(result.panelCapacity) ? '-' : String(result.panelCapacity);
        return panelCapacity === String(value);
      },
      sorter: (a, b) => getSortedNumber(a.panelCapacity, b.panelCapacity),
      render: (_, item: EmailNotificationTargetSiteInfo) => (Number.isNaN(item.panelCapacity) ? '-' : item.panelCapacity),
    },
  ];

  return (
    <Modal
      visible={visible}
      onCancel={handleCloseModal}
      centered
      footer={null}
      width={1850}
      className="activity-check-mail-setting-display-modal"
    >
      <div className="solar-electric-activity-check-mail-send-setting-container">
        <Spin spinning={loading}>
          <Title level={4}>
            発電・死活監視メール
            {targetInfo.notificationGroup ? '編集' : '追加'}
          </Title>
          <Divider className="email-notification-group-settings-modal-divider" />

          <Row>
            <div>分類名</div>
            <Input
              id="notificationGroup"
              value={editedNotificationGroupName}
              onChange={handleChangeNotificationGroup}
              style={{ width: '50%' }}
              maxLength={50}
              placeholder="50文字以内"
            />
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <div>通知先グループ</div>
            <div className="email-notification-group-settings-modal-select">
              <Select
                id="targetGroupIds"
                mode="multiple"
                value={editTargetGroups}
                onChange={handleChangeTargetGroups}
                style={{ width: '50%' }}
              >
                {targetGroupOptions}
              </Select>
            </div>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <div>通知メール</div>
            <CheckboxGroup
              value={editMailTypes}
              onChange={(value) => handleChangeEmailTypes(value)}
            >
              <Row>
                <Col span={24}>
                  <Checkbox value="daily">発電診断（日次）</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="monthly">発電診断（月次）</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="activity">死活監視</Checkbox>
                </Col>
              </Row>
            </CheckboxGroup>
          </Row>
          <Row style={{ marginTop: '15px' }}>
            <div>通知対象サイト</div>
            <Col span={24}>
              <ul>
                <Table<EmailNotificationTargetSiteInfo>
                  rowSelection={{ ...rowSelection }}
                  dataSource={siteInformationList}
                  pagination={false}
                  columns={columns}
                  rowKey="externalId"
                  scroll={{ x: 1500, y: 430 }}
                  className="activity-check-mail-setting-modal-target-site-table"
                />
              </ul>
            </Col>
          </Row>
          <Row>
            <div className="activity-check-mail-setting-modal-add-button">
              <Tooltip title={buttonDisabled && ERROR_NO_AUTH_MESSAGE}>
                <Button
                  type="primary"
                  onClick={handleAddMailSetting}
                  disabled={buttonDisabled}
                >
                  {targetInfo.notificationGroup ? '更新' : '追加'}
                </Button>
              </Tooltip>
            </div>
          </Row>
        </Spin>
      </div>
    </Modal>
  );
};

export default EmailNotificationGroupSettingsModal;
