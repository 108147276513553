import React, { useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  message,
  Spin,
  Tree,
  TreeSelect,
} from 'antd';
import { AntTreeNodeSelectedEvent } from 'antd/lib/tree';

import OMEquipment from '../../../../../utils/Asset/OMEquipment';
import TreeNode from '../../../../../utils/common/TreeNode';
import OMAlertEvent from '../../../../../utils/Event/OMAlertEvent';
import { ERROR_LOAD_FACILITY_ASSET_TREE } from '../../../../../utils/messages';

import './OMEquipmentTree.css';

interface OMEquipmentTreeSelectProps {
  alertLoading: boolean;
  occurringMeasurementEvents: OMAlertEvent[];
  areaAssetId?: number;
  displayReload: number;
  onSelect: (asset?: OMEquipment) => void;
}

/**
 * 特高監視ダッシュボード計測一覧画面 計測ポイント選択プルダウンコンポーネント
 * @param {OMEquipmentTreeSelectProps} props プロパティ
 * @returns 特高監視ダッシュボード計測一覧画面 計測ポイント選択プルダウン
 */
const OMEquipmentTree: React.FC<OMEquipmentTreeSelectProps> = (props: OMEquipmentTreeSelectProps) => {
  const {
    alertLoading,
    occurringMeasurementEvents,
    areaAssetId,
    displayReload,
    onSelect,
  } = props;

  const [selectValue, setSelectValue] = useState<string>();
  const [visible, setVisible] = useState<boolean>(false);
  const [rootTreeNode, setRootTreeNode] = useState<TreeNode<OMEquipment>>();
  const [loading, setLoading] = useState<boolean>(true);

  /**
   * イベントハンドラ
   */
  useEffect(
    () => {
      if (!areaAssetId) return () => { /* 何もしない */ };

      setLoading(true);
      let canceled = false;
      (async () => {
        let loadRootTreeNode: TreeNode<OMEquipment> | undefined;
        try {
          const site = await OMEquipment.loadOneByIdFromCDF(areaAssetId);
          if (!site) {
            throw new Error();
          }

          loadRootTreeNode = await site.loadOMEquipmentTreeFromCDF();
        } catch (exception) {
          message.error(ERROR_LOAD_FACILITY_ASSET_TREE);
        }

        if (!canceled) {
          setRootTreeNode(loadRootTreeNode);
          setLoading(false);
        }
      })();
      return () => { canceled = true; };
    }, [areaAssetId, displayReload],
  );

  /**
   * ツリー選択時のイベントハンドラ
   * @param {string[]} selectedKeys 選択したTreeNodeのkey
   * @param {AntTreeNodeSelectedEvent} info TreeNodeの選択イベント情報
   */
  const handleSelect = (selectedKeys: string[], info: AntTreeNodeSelectedEvent): void => {
    if (info.selected) {
      const key = String(selectedKeys[0]);
      const facilityNode = rootTreeNode?.findNodeByKey(key);
      if (facilityNode) {
        onSelect(facilityNode.data);
        setSelectValue(facilityNode.data.name);
        setVisible(!visible);
      }
    }
  };

  /**
   * Drawer開閉のイベントハンドラ
   */
  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  /**
   * 設備情報プルダウン用TreeNodeのレンダリング
   * @param {TreeNode<OMEquipment>[]} treeNodes 計測ポイント選択プルダウン用treeNodeリスト
   * @returns {JSX.Element[]} 計測ポイント選択プルダウン用TreeNode
   */
  const renderTreeNodes = (treeNodes: TreeNode<OMEquipment>[]): JSX.Element[] => (
    treeNodes.map((treeNode) => {
      const {
        assetLevel,
        id,
        hasChildFloors,
        name,
      } = treeNode.data;

      const facilityAlert = occurringMeasurementEvents.some(({ assetIds }) => assetIds?.includes(id));
      const floorHasAlert = assetLevel === 'floor' && !hasChildFloors && facilityAlert;
      const titleColor = floorHasAlert ? 'red' : 'black';
      const isNodeDisabled = ['pcs', 'feeder'].includes(assetLevel);
      const facilityClassName = `mobile-om-equipment-tree-title-${isNodeDisabled ? 'disabled' : titleColor}`;

      return treeNode.isLeaf
        ? (
          <TreeSelect.TreeNode
            key={`${id}`}
            title={<span className={facilityClassName} id={`${id}`}>{name}</span>}
            disabled={isNodeDisabled}
            value={`${id}`}
          />
        )
        : (
          <TreeSelect.TreeNode
            key={`${id}`}
            title={<span className={facilityClassName} id={`${id}`}>{name}</span>}
            disabled={isNodeDisabled}
            value={`${id}`}
          >
            {renderTreeNodes(treeNode.children)}
          </TreeSelect.TreeNode>
        );
    })
  );

  return (
    <>
      <Button
        className={`mobile-om-equipment-tree-button${selectValue ? '' : '-no-select'}`}
        onClick={handleVisibleChange}
        loading={loading}
      >
        {selectValue ?? '設備を選択'}
      </Button>
      <Drawer
        title="特高監視ダッシュボード"
        className="mobile-om-equipment-tree-drawer"
        placement="left"
        width="80vw"
        closable={false}
        onClose={handleVisibleChange}
        visible={visible}
      >
        <Spin spinning={alertLoading}>
          <Tree
            defaultExpandAll
            blockNode
            onSelect={handleSelect}
          >
            {renderTreeNodes(rootTreeNode ? [rootTreeNode] : [])}
          </Tree>
        </Spin>
      </Drawer>
    </>
  );
};

export default OMEquipmentTree;
