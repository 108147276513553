import React, { useEffect, useState } from 'react';

import {
  Card,
  Col,
  List,
  Row,
  Tooltip,
} from 'antd';

import OMAlertEvent from '../../../../utils/Event/OMAlertEvent';

import './FacilityList.css';

interface FacilityListProps {
  targetFacilities: OMAlertEvent[];
}

const FACILITY_LIST_HEIGHT = 127;
const STATUS_COLOR_ORANGE = '#fd7f17';
const STATUS_COLOR_GREEN = '#79bd28';

/**
 * 対象設備名のコンポーネント
 * @param {FacilityListProps} props プロパティ
 * @returns フロア名、対象設備リスト
 */
const FacilityList: React.FC<FacilityListProps> = (props: FacilityListProps) => {
  const { targetFacilities } = props;
  const [facilityListHeight, setFacilityListHeight] = useState<number>(FACILITY_LIST_HEIGHT);

  // フロア名の高さを取得
  const listElementNumber = Math.ceil(targetFacilities.length / 4);
  const listElementHeight = (listElementNumber > 1) ? 127 + 143 * (listElementNumber - 1) : 127;

  /*
   * イベントハンドラ
   */
  useEffect(() => {
    setFacilityListHeight(listElementHeight);
  }, [listElementHeight]);

  /**
   * 設備状態の取得
   * @param {string} equipmentType 設備種別
   * @param {boolean} happening 発生有無
   * @returns {string} 設備状態
   */
  const getFacilityStatus = (equipmentType: string, happening: boolean) => {
    if (equipmentType === 'vd') {
      return happening ? '加圧有' : '加圧無';
    }
    if (equipmentType === 'mode') {
      return happening ? '遠方' : '手動';
    }
    return happening ? 'ON' : 'OFF';
  };

  /**
   * 設備状態の文字色を指定
   * @param {string} equipmentType 設備種別
   * @param {boolean} happening 発生有無
   * @returns {string} 文字の色(オレンジor緑)
   */
  const getStatusDisplayColor = (equipmentType: string, happening: boolean) => {
    if (equipmentType === 'mode') {
      return happening ? STATUS_COLOR_GREEN : STATUS_COLOR_ORANGE;
    }
    return happening ? STATUS_COLOR_ORANGE : STATUS_COLOR_GREEN;
  };

  return (
    <Row>
      <Col span={2} className="om-dashboard-facility-floor-name" style={{ minHeight: facilityListHeight }}>
        <div className="om-dashboard-facility-floor-name-element">{targetFacilities[0].floorName}</div>
      </Col>
      <Col span={2} className="om-dashboard-facility-name-list">
        <List
          grid={{
            gutter: 0,
            column: 4,
          }}
          dataSource={targetFacilities}
          renderItem={(item) => (
            <List.Item>
              <Card
                className="om-dashboard-facility-name-card"
                title={<Tooltip title={item.monitorName}>{item.monitorName}</Tooltip>}
                bodyStyle={{ color: getStatusDisplayColor(item.equipmentType, item.happening) }}
              >
                {getFacilityStatus(item.equipmentType, item.happening)}
              </Card>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default FacilityList;
