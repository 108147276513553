import React from 'react';
import { Collapse, Descriptions } from 'antd';

import OMAlertEvent from '../../../../../utils/Event/OMAlertEvent';

import './FacilityList.css';

const { Panel } = Collapse;

interface FacilityListProps {
  targetFacilities: OMAlertEvent[];
}

const STATUS_COLOR_ORANGE = 'orange';
const STATUS_COLOR_GREEN = 'green';

/**
 * 対象設備名のコンポーネント
 * @param {FacilityListProps} props プロパティ
 * @returns フロア名、対象設備リスト
 */
const FacilityList: React.FC<FacilityListProps> = (props: FacilityListProps) => {
  const { targetFacilities } = props;

  /**
   * 設備状態の取得
   * @param {string} equipmentType 設備種別
   * @param {boolean} happening 発生有無
   * @returns {string} 設備状態
   */
  const getFacilityStatus = (equipmentType: string, happening: boolean): string => {
    if (equipmentType === 'vd') {
      return happening ? '加圧有' : '加圧無';
    }
    if (equipmentType === 'mode') {
      return happening ? '遠方' : '手動';
    }
    return happening ? 'ON' : 'OFF';
  };

  /**
   * 設備状態のクラス名を取得
   * @param {string} equipmentType 設備種別
   * @param {boolean} happening 発生有無
   * @returns {string} クラス名
   */
  const getStatusClassName = (equipmentType: string, happening: boolean): string => {
    if (equipmentType === 'mode') {
      return `mobile-facility-list-${happening ? STATUS_COLOR_GREEN : STATUS_COLOR_ORANGE}`;
    }
    return `mobile-facility-list-${happening ? STATUS_COLOR_ORANGE : STATUS_COLOR_GREEN}`;
  };

  /**
   * 設備名と設備状態を描画
   * @param {OMAlertEvent} facilityAlert 設備情報
   * @returns {JSX.Element} Descriptions.Item(設備情報)
   */
  const getDescriptionsItems = (facilityAlert: OMAlertEvent): JSX.Element => {
    const {
      equipmentType,
      happening,
      id,
      monitorName,
    } = facilityAlert;

    return (
      <Descriptions.Item
        key={id}
        label={monitorName}
        className={getStatusClassName(equipmentType, happening)}
      >
        {getFacilityStatus(equipmentType, happening)}
      </Descriptions.Item>
    );
  };

  return (
    <div className="mobile-facility-list-content">
      {!targetFacilities.length ? (
        <div className="mobile-facility-list-title">対象設備</div>
      ) : (
        <Collapse expandIconPosition="right" defaultActiveKey="1">
          <Panel
            header="対象設備"
            key="1"
            className="mobile-facility-body"
          >
            <Descriptions bordered column={1}>
              {targetFacilities.map((targetFacility) => getDescriptionsItems(targetFacility))}
            </Descriptions>
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default FacilityList;
