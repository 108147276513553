import React, { useState } from 'react';
import {
  Button,
  Col,
  Tabs,
  Row,
  Layout,
  Spin,
} from 'antd';

import LayoutModal from './LayoutModal';
import OccurringMeasurementPoint from './OccurringMeasurementPoint';
import StatDisplay from './StatDisplay';
import { DisplayInfo } from '../OperationAndMaintenance';
import OMAlertEvent from '../../../../../utils/Event/OMAlertEvent';
import OMStatusEvent from '../../../../../utils/Event/OMStatusEvent';

import './CommonHeaderDisplay.css';

const { Header } = Layout;
const { TabPane } = Tabs;

interface CommonHeaderDisplayProps {
  areaAssetId: number;
  displayList: DisplayInfo[];
  handleTabChange: (selectedKey: string) => void;
  statusEvents: OMStatusEvent[];
  occurringMeasurementEvents: OMAlertEvent[];
  loading: boolean;
  handleClickReload: () => void;
}

/**
 * O&M特高監視共通ヘッダーコンポーネント
 * @param {CommonHeaderDisplayProps} props プロパティ
 * @returns O&M特高監視共通ヘッダー
 */
const CommonHeaderDisplay: React.FC<CommonHeaderDisplayProps> = (props: CommonHeaderDisplayProps) => {
  const {
    areaAssetId,
    displayList,
    handleTabChange,
    statusEvents,
    occurringMeasurementEvents,
    loading,
    handleClickReload,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  /**
   * 子画面の表示/非表示処理
   */
  const toggleLayoutModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div className="mobile-common-header-content">
      <Spin spinning={loading}>
        <Header style={{ backgroundColor: '#f0f2f5', height: 'auto' }}>
          <Row className="mobile-common-header-tabs">
            <Tabs defaultActiveKey="1" className="mobile-common-header-tab-items" onChange={handleTabChange}>
              {displayList.map(({ name, key }) => (
                <TabPane tab={name} key={key} />
              ))}
            </Tabs>
          </Row>
          <Row className="mobile-common-header-stat-display" gutter={16}>
            <StatDisplay statusEvents={statusEvents} />
          </Row>
          <Row>
            <OccurringMeasurementPoint occurringMeasurementPoints={occurringMeasurementEvents} />
          </Row>
          <Row className="mobile-common-header-button-items">
            <Col style={{ textAlign: 'right', paddingTop: '1%' }}>
              <Button className="mobile-common-header-layout-display-button" type="primary" onClick={toggleLayoutModal}>
                レイアウト表示
              </Button>
              <LayoutModal
                areaAssetId={areaAssetId}
                visible={isModalVisible}
                onClose={toggleLayoutModal}
              />
              <Button
                icon="reload"
                onClick={handleClickReload}
                disabled={loading}
                style={{ marginLeft: '5px' }}
              />
            </Col>
          </Row>
        </Header>
      </Spin>
    </div>
  );
};

export default CommonHeaderDisplay;
