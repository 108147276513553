import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  message,
  Row,
  Select,
  Spin,
} from 'antd';
import { Moment, unitOfTime } from 'moment';

import MeasurementSelect from './parts/MeasurementSelect';
import MeasurementTable from './parts/MeasurementTable';
import OMEquipment, { FILTER_MODE_MEASUREMENT } from '../../../../utils/Asset/OMEquipment';
import TreeNode from '../../../../utils/common/TreeNode';
import { ERROR_LOAD_FACILITY_ASSET_TREE } from '../../../../utils/messages';
import OMMeasurement from '../../../../utils/Timeseries/OMMeasurement/OMMeasurement';
import { RetrievalTimeRangeInfo, TIME_RANGE_INFO_FOR_DISPLAY } from '../../../../utils/Timeseries/OMMeasurement/TimeInterval';

import './MeasurementList.css';

const { Panel } = Collapse;
const { Option } = Select;

interface MeasurementListProps {
  areaAssetId: number;
}

/**
 * 計測一覧画面のコンポーネント
 * @param {MeasurementListProps} props プロパティ
 * @returns 計測一覧画面
 */
const MeasurementList: React.FC<MeasurementListProps> = (props: MeasurementListProps) => {
  const { areaAssetId } = props;

  const [treeDataLoading, setTreeDataLoading] = useState<boolean>(true);
  const [rootTreeNode, setRootTreeNode] = useState<TreeNode<OMEquipment>>();
  const [startDate, setStartDate] = useState<Moment>();
  const [timeRange, setTimeRange] = useState<RetrievalTimeRangeInfo>();
  /* 種別の選択肢（timeRange）をテーブルの表示形式に紐付けているとテーブル表示後も種別の選択に応じて日付の表示が変わってしまうので、
      表示用の種別を保持するstate変数を用意し、検索完了時のみセットしてテーブルに渡すように修正 */
  const [displayTimeRange, setDisplayTimeRange] = useState<RetrievalTimeRangeInfo>();
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [searchPanelKey, setSearchPanelKey] = useState<string | string[]>(['1']);
  const [measurementData, setMeasurementData] = useState<OMMeasurement[]>();
  const [measurementDataLoading, setMeasurementDataLoading] = useState<boolean>(false);

  /**
   * 指定された計測値のタイムシリーズを一覧で取得し、それぞれについて指定期間内のデータポイントも取得しまとめて返却する
   * @param {Moment} start 表示開始日時
   * @param {RetrievalTimeRangeInfo} selectedRange 表示期間の種別
   * @returns {Promise<OMMeasurement[]>} 指定した期間中のデータポイントを含む対象計測値データの一覧
   */
  const retrieveTargetMeasurements = async (start: Moment, selectedRange: RetrievalTimeRangeInfo): Promise<OMMeasurement[]> => {
    const { addTimeSpan } = selectedRange;
    const from = start.clone().startOf(addTimeSpan as unitOfTime.StartOf);
    const to = from.clone().endOf(addTimeSpan);
    return OMMeasurement.loadAllSelectedMeasurements(
      checkedItems.map((value) => Number(value)),
      from.toDate(),
      to.toDate(),
      selectedRange.range,
    );
  };

  /*
   * イベントハンドラ
   */

  /**
   * 表示開始期間をカレンダー（DatePicker)で選択・変更した際のイベントハンドラ
   * @param {Moment | null} date 日付
   */
  const handleDateSelect = (date: Moment | null) => {
    const selectedDate = date ?? undefined;
    setStartDate(selectedDate);
  };

  /**
   * 計測グループ・計測ポイントのプルダウンから表示対象計測値の選択を変更した際のイベントハンドラ
   * @param {string[]} changeValue 変更後の計測ポイントのID一覧
   */
  const handleTreeSelectChange = (changeValue: string[]) => {
    setCheckedItems(changeValue);
  };

  /**
   * 「表示」ボタンタップ時のイベントハンドラ
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e クリック(タップ)イベント
   */
  const handleClickSearchButton = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setMeasurementDataLoading(true);

    // 表示条件設定の項目に一つでも未設定のものがあった場合
    if (!(!!startDate && !!timeRange && !!checkedItems.length)) {
      message.error('表示開始時期、種別、計測ポイントのすべてを指定してください。');
      setMeasurementDataLoading(false);
      return;
    }

    // 計測ポイントが5つ以上選択されている場合
    if (checkedItems.length > 4) {
      message.error('表示対象の計測ポイントは4つ以下を指定してください。');
      setMeasurementDataLoading(false);
      return;
    }

    // 表示条件設定のパネルを閉じてから対象データを取得する
    setSearchPanelKey([]);
    const targetMeasurements = await retrieveTargetMeasurements(startDate, timeRange);
    if (
      !targetMeasurements.length
      || targetMeasurements.every(({ transformedDatapoints }) => !transformedDatapoints.length)
    ) {
      message.error('指定した条件に合致するデータが見つかりませんでした。');
      setMeasurementDataLoading(false);
      return;
    }

    setMeasurementData(targetMeasurements);
    setDisplayTimeRange(timeRange);
    setMeasurementDataLoading(false);
  };

  /**
   * 「条件クリア」ボタンタップ時のイベントハンドラ
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e クリック(タップ)イベント
   */
  const handleClickClear = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    e.currentTarget.blur();
    setStartDate(undefined);
    setTimeRange(undefined);
    setCheckedItems([]);
    setMeasurementData(undefined);
    // 表示条件設定が閉じている場合は再度開く（初期表示時は開いているため）
    if (!searchPanelKey.length) {
      setSearchPanelKey(['1']);
    }
  };

  useEffect(
    () => {
      if (!treeDataLoading || !areaAssetId) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        let loadRootTreeNode: TreeNode<OMEquipment> | undefined;
        try {
          const site = await OMEquipment.loadOneByIdFromCDF(areaAssetId);
          if (!site) {
            throw new Error();
          }

          loadRootTreeNode = await site.loadOMEquipmentTreeFromCDF(FILTER_MODE_MEASUREMENT);
        } catch (exception) {
          message.error(ERROR_LOAD_FACILITY_ASSET_TREE);
        }

        if (!canceled) {
          setRootTreeNode(loadRootTreeNode);
          setTreeDataLoading(false);
        }
      })();

      return () => { canceled = true; };
    },
    [areaAssetId, treeDataLoading],
  );

  return (
    <div style={{ width: '98%', margin: '0px auto 1%' }}>
      <Collapse className="mobile-display-measurement-search-options" activeKey={searchPanelKey} onChange={setSearchPanelKey}>
        <Panel
          key="1"
          header={(
            <Fragment key="search_panel_header">
              <Row type="flex" justify="start" align="middle" style={{ height: '33%' }} gutter={[16, 16]}>
                <Col span={9}>
                  <h3>表示条件設定</h3>
                </Col>
                <Col span={15} style={{ textAlign: 'right' }}>
                  <Button
                    type="primary"
                    id="om-dashboard-measurement-search-button"
                    loading={measurementDataLoading}
                    onClick={handleClickSearchButton}
                  >
                    表示
                  </Button>
                  <Button
                    id="om-dashboard-measurement-clear-button"
                    onClick={handleClickClear}
                    style={{ marginLeft: '5px' }}
                  >
                    条件クリア
                  </Button>
                </Col>
              </Row>
              <Row style={{ height: '67%' }}>
                {/* カレンダー操作時に表示条件設定メニューの開閉を防ぐためにColのクリック(タップ)ハンドラで制御を追加 */}
                <Col span={24} style={{ display: 'flex', flexDirection: 'column' }} onClick={(e) => e.stopPropagation()}>
                  <span style={{ paddingBottom: 5 }}>●表示開始期間 </span>
                  <DatePicker
                    id="om-dashboard-measurement-date-picker"
                    placeholder="日付を選択"
                    format="YYYY/MM/DD"
                    value={startDate}
                    onChange={handleDateSelect}
                    onFocus={(e) => e.stopPropagation()}
                  />
                </Col>
              </Row>
            </Fragment>
          )}
          style={{ width: '100%' }}
        >
          <Row>
            <div style={{ paddingBottom: 10 }}>
              <span>●種別 </span>
            </div>
            <div>
              <Select
                id="om-dashboard-measurement-range-select"
                value={timeRange?.range}
                onChange={(value) => {
                  const selectedRange = TIME_RANGE_INFO_FOR_DISPLAY.find(
                    (rangeInfo) => rangeInfo.range === value,
                  );
                  setTimeRange(selectedRange);
                }}
                aria-label="種別"
                style={{ width: '100%', boxSizing: 'border-box' }}
                dropdownStyle={{
                  maxWidth: '100%',
                  overflow: 'scroll',
                  maxHeight: 200,
                  boxSizing: 'border-box',
                }}
              >
                {TIME_RANGE_INFO_FOR_DISPLAY.map((rangeInfo) => (
                  <Option key={`option_${rangeInfo.range}`} value={rangeInfo.range}>
                    {rangeInfo.displayName}
                  </Option>
                ))}
              </Select>
            </div>
          </Row>
          <Row>
            <div style={{ paddingBottom: 10 }}>
              <span>●計測グループ・計測ポイント </span>
            </div>
            <Spin spinning={treeDataLoading}>
              <MeasurementSelect
                rootTreeNode={rootTreeNode}
                checkedItems={checkedItems}
                onChange={handleTreeSelectChange}
              />
            </Spin>
          </Row>
        </Panel>
      </Collapse>
      {(measurementData && displayTimeRange) && (
        <MeasurementTable
          measurements={measurementData}
          timeRange={displayTimeRange}
          loading={measurementDataLoading}
        />
      )}
    </div>
  );
};

export default MeasurementList;
