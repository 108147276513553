/**
 * ブラウザにuserAgentData（クライアントヒントを取得できるnavigatorのプロパティ）が実装されているかを判定し、
 * あればその中のmobile（モバイル端末からアクセスしている場合はtrueを返す）を返却することでデバイス種別を判定する
 * @returns {boolean} 判定結果（モバイル端末であればtrue）
 */
const checkNavigatorUAData = () => (
  'userAgentData' in navigator
  && 'mobile' in navigator.userAgentData
  && navigator.userAgentData.mobile
);

/**
 * クライアントヒント、ユーザエージェント文字列、対象デバイスのタッチ操作可否をnavigatorの各種プロパティから取り出し、
 * アプリにアクセスしているデバイスがモバイル端末かどうかを判定する
 * @returns {boolean} 判定結果（モバイル端末であればtrue）
 */
const isDeviceMobile = () => {
  const isUADataMobile = checkNavigatorUAData();
  const isMobileOS = !('userAgent' in navigator) || ['android', 'iphone'].some(
    (device) => navigator.userAgent.toLowerCase().includes(device),
  );
  const isTouchDevice = 'ontouchstart' in window || ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0);
  return isUADataMobile || (isMobileOS && isTouchDevice);
};

export default isDeviceMobile;
