import React from 'react';
import { Collapse, List } from 'antd';

import OMAlertEvent from '../../../../../utils/Event/OMAlertEvent';

import './MeasurementPoint.css';

const { Panel } = Collapse;

interface MeasurementPointProps {
  targetPoints: OMAlertEvent[];
}

/**
 * 計測ポイント表示のコンポーネント
 * @param {MeasurementPointProps} props プロパティ
 * @returns 計測ポイント
 */
const MeasurementPoint: React.FC<MeasurementPointProps> = (props: MeasurementPointProps) => {
  const { targetPoints } = props;

  /**
   * 発生中の計測ポイント状態のクラス名を取得する
   * @param {number} severity 重要度
   * @returns {string} クラス名
   */
  const getMeasurementPointClassName = (severity: number): string => {
    const measurementColor = severity === 1 ? 'red' : 'yellow';
    return `mobile-measurement-point-${measurementColor}`;
  };

  return (
    <div className="mobile-measurement-point-content">
      {!targetPoints.length ? (
        <div className="mobile-measurement-point-title">計測ポイント</div>
      ) : (
        <Collapse expandIconPosition="right" defaultActiveKey="1">
          <Panel
            className="mobile-measurement-point-body"
            header="計測ポイント"
            key="1"
          >
            <div className="mobile-measurement-point-list">
              <List
                dataSource={targetPoints}
                renderItem={(item) => (
                  <List.Item className="mobile-measurement-point-list-item">
                    <span className={item.happening ? getMeasurementPointClassName(item.severity) : undefined}>
                      {item.happening ? '●' : '○'}
                    </span>
                    {item.monitorName}
                  </List.Item>
                )}
              />
            </div>
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default MeasurementPoint;
