import React, { useEffect, useState } from 'react';
import {
  Button,
  Layout,
  Menu,
  Modal,
} from 'antd';
import { ClickParam } from 'antd/lib/menu';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Auth } from 'aws-amplify';
import { MenuOutlined } from '@ant-design/icons';
import { CogniteClient } from '@cognite/sdk';
import { createBrowserHistory } from 'history';
import {
  HashRouter,
  Link,
  Redirect,
  Route,
} from 'react-router-dom';

import OperationAndMaintenance from '../Solar/OperationAndMaintenance/OperationAndMaintenance';
import Main from '../../Main/Main';
import OperationAndMaintenanceDashboard from '../../Solar/OperationAndMaintenance/OperationAndMaintenanceDashboard';
import { ResponseRoadProject } from '../../../utils/AWS/ApiGateway';
import { sdfLogOut } from '../../../utils/common';
import { LOGOUT_CONFIRM_MESSAGE } from '../../../utils/messages/index';
import { getStorageMenuKey, removeStorageData, setStorageMenuKey } from '../../../utils/storageCommon';
import './MobileMain.css';

const { Content, Header } = Layout;

// 認証成功時にブラウザに保持されるログインユーザの権限情報
interface PermissionFunction {
  accessibleScreenList: string[];
  actions: string[];
  userRole: string;
}

// 画面描画・遷移に用いるルーティング情報
interface RouteInfo {
  menuItems: JSX.Element[];
  routes: JSX.Element[];
}

// ブラウザに保持されるログインユーザの権限情報のデフォルト値（権限なし）
const DEFAULT_PERMISSIONS: PermissionFunction = {
  accessibleScreenList: [],
  actions: [],
  userRole: '',
};

// モバイル表示対象の画面名（DynamoDBのaccessible-screen-listテーブルのキー項目）の一覧
const MOBILE_DISPLAY_LIST = ['solarOMDashboard'];

// モバイル向けルーティング情報のデフォルト値（両項目ともに空の配列となる）
const DEFAULT_ROUTES: RouteInfo = { menuItems: [], routes: [] };

/**
 * ログインユーザがアクセス可能な画面とモバイル向けに表示する対象画面の一覧を突合し、
 * その結果をもってモバイル向けのルーティング情報を構成する
 * @param {string[]} screenList ログインユーザからアクセス可能な画面名の一覧
 * @returns {RouteInfo} モバイル向けのルーティング情報
 */
const generateMenuItemsAndRoutes = (screenList: string[]): RouteInfo => {
  const mobileScreenList = screenList.filter((screen) => MOBILE_DISPLAY_LIST.includes(screen));
  if (!mobileScreenList.length) return DEFAULT_ROUTES;

  const menuItems = [];
  const routes = [];

  if (mobileScreenList.includes('solarOMDashboard')) {
    menuItems.push(
      <Menu.Item key="operation-and-maintenance-dashboard">
        <Link to="/">
          <span className="submenu-text">特高監視ダッシュボード</span>
        </Link>
      </Menu.Item>,
    );
    routes.push(
      <Route
        key="operation-and-maintenance-dashboard-key"
        exact
        path="/"
        render={() => (<OperationAndMaintenanceDashboard />)}
      />,
      <Route
        key="operation-and-maintenance-dashboard-redirect-key"
        path="/OperationAndMaintenanceDashboard"
        render={() => <Redirect to="/" />}
      />,
      <Route
        key="operation-and-maintenance-key"
        path="/OperationAndMaintenance"
        render={() => (<OperationAndMaintenance />)}
      />,
    );
  } else {
    routes.push(
      <Route
        key="operation-and-maintenance-dashboard-key"
        path="/OperationAndMaintenanceDashboard"
        render={() => <Redirect to="/" />}
      />,
      <Route
        key="operation-and-maintenance-key"
        path="/OperationAndMaintenance"
        render={() => <Redirect to="/" />}
      />,
    );
  }

  return { menuItems, routes };
};

interface MobileMainProps {
  client: CogniteClient;
  roadproject: ResponseRoadProject;
}

/**
 * モバイルアクセス用のプリセットAPメニュー
 * @param {MobileMainProps} props プロパティ
 * @returns モバイルアクセス用のプリセットAPメニューコンポーネント
 */
const MobileMain: React.FC<MobileMainProps> = (props: MobileMainProps) => {
  const { client, roadproject } = props;
  const [isMobileUser, setIsMobileUser] = useState<boolean>(true);
  const [selectedMenuKey, setSelectedMenuKey] = useState<string>('1');
  const [routeInfo, setRouteInfo] = useState<RouteInfo>(DEFAULT_ROUTES);
  const [logoutModalVisible, setLogoutModalVisible] = useState<boolean>(false);

  /**
   * 選択されているメニュー項目のキー値を取得する
   * @returns メニュー項目のキー値
   */
  const getMenuKey = () => {
    const history = createBrowserHistory();
    const { hash } = history.location;

    return hash === '#/' ? '1' : getStorageMenuKey();
  };

  useEffect(() => {
    let canceled = false;

    (async () => {
      const targetMenuKey = getMenuKey();
      const funcCache = await caches.open('sdf-preset-ap-func');
      const cacheData = await funcCache.match('/authFunction').then((match) => ((match?.json() ?? {})));
      const permissions = (
        'PermissionFunction' in cacheData
          ? cacheData.PermissionFunction
          : DEFAULT_PERMISSIONS
      ) as PermissionFunction;
      const { accessibleScreenList } = permissions;
      const allRouteInfo = generateMenuItemsAndRoutes(accessibleScreenList);
      const isRouteInfoDefault = Object.is(allRouteInfo, DEFAULT_ROUTES);
      if (!canceled) {
        setIsMobileUser(!isRouteInfoDefault);
        setSelectedMenuKey(targetMenuKey);
        setRouteInfo(allRouteInfo);
      }
    })();

    return () => { canceled = true; };
  }, []);

  /**
   * ログアウトモーダル開閉時のイベントハンドラ
   */
  const toggleLogoutModal = () => {
    setLogoutModalVisible(!logoutModalVisible);
  };

  /**
   * メニュー項目選択時のイベントハンドラ
   * @param {ClickParam} params メニュー項目選択時に渡されるパラメータ
   */
  const onTapMenuItem = (params: ClickParam) => {
    const { key } = params;
    setStorageMenuKey(key);
    setSelectedMenuKey(key);
  };

  /**
   * ログアウトモーダル内のOKボタンタップ時のイベントハンドラ
   */
  const onTapLogOut = async () => {
    removeStorageData();
    sdfLogOut();
    await Auth.signOut();
  };

  const { menuItems, routes } = routeInfo;
  return (
    // モバイル向けに表示する画面一覧の中でログインユーザが閲覧できるものが一つもない場合はPC版のメニューコンポーネントを代わりに描画する
    isMobileUser
      ? (
        <Layout hasSider={false} className="mobile-display-layout">
          <HashRouter>
            <Header className="mobile-menu-bar">
              <Menu
                mode="inline"
                theme="dark"
                selectedKeys={[selectedMenuKey]}
                onClick={onTapMenuItem}
              >
                <SubMenu key="mobile-menu-bar-submenu" title={(<span><MenuOutlined /></span>)}>
                  {menuItems.map((item) => item)}
                </SubMenu>
              </Menu>
              <Button icon="./icon/Logout.png" className="mobile-menu-logout-button" onClick={toggleLogoutModal}>
                <img src="./icon/Logout.png" alt="ログアウト" />
              </Button>
            </Header>
            <Content style={{ overflow: 'auto' }}>
              {routes.map((item) => item)}
            </Content>
          </HashRouter>
          <Modal
            wrapClassName="mobile-menu-logout-modal"
            visible={logoutModalVisible}
            closable={false}
            okText="O K"
            onOk={onTapLogOut}
            onCancel={toggleLogoutModal}
          >
            <p style={{ margin: 0, textAlign: 'center' }}>
              {LOGOUT_CONFIRM_MESSAGE}
            </p>
          </Modal>
        </Layout>
      )
      : (
        <Main client={client} roadproject={roadproject} />
      )
  );
};

export default MobileMain;
