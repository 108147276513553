import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Typography,
  Row,
  Layout,
  Spin,
} from 'antd';

import StatDisplay from './StatDisplay';
import OccurringMeasurementPoint from './OccurringMeasurementPoint';
import { DisplayInfo } from '../OperationAndMaintenance';
import OMAlertEvent from '../../../../utils/Event/OMAlertEvent';
import OMStatusEvent from '../../../../utils/Event/OMStatusEvent';

const { Title } = Typography;
const { Header } = Layout;

interface CommonHeaderDisplayProps {
  areaAssetId: number;
  displayList: DisplayInfo[];
  activeDisplay: DisplayInfo;
  handleTabChange: (selectedKey: string) => void;
  occurringMeasurementEvents:OMAlertEvent[];
  alertLoading: boolean;
  displayReload: number;
  handleClickReload: () => void;
}

/**
 * O&M特高監視共通ヘッダーコンポーネント
 * @param {CommonHeaderDisplayProps} props プロパティ
 * @returns O&M特高監視共通ヘッダー
 */
const CommonHeaderDisplay: React.FC<CommonHeaderDisplayProps> = (props: CommonHeaderDisplayProps) => {
  const {
    areaAssetId,
    displayList,
    activeDisplay,
    handleTabChange,
    occurringMeasurementEvents,
    alertLoading,
    displayReload,
    handleClickReload,
  } = props;

  const [statusEventList, setStatusEventList] = useState<OMStatusEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  /*
   * イベントハンドラ
   */

  useEffect(
    () => {
      if (!areaAssetId) return () => { /* 何もしない */ };

      setLoading(true);
      let canceled = false;
      (async () => {
        const statusEvents = await OMStatusEvent.loadStatusEvents(areaAssetId);

        if (!canceled) {
          setStatusEventList(statusEvents);
          setLoading(false);
        }
      })();

      return () => { canceled = true; };
    },
    [areaAssetId, displayReload],
  );

  /**
   * 表示されている画面以外の画面への切り替え用ボタン作成処理
   * @param {DisplayInfo[]} selectedDisplayList 切り替えボタン作成対象画面の一覧
   * @returns {JSX.Element[]} 対象画面への切り替え用ボタンの一覧
   */
  const createDisplayButtons = (selectedDisplayList: DisplayInfo[]): JSX.Element[] => (
    selectedDisplayList.map(({ name, key }) => (
      <Button
        key={name}
        style={{ marginRight: 10 }}
        title={name}
        onClick={() => handleTabChange(key)}
      >
        {name}
      </Button>
    ))
  );

  return (
    <>
      <Header style={{ backgroundColor: '#f0f2f5', height: 'auto' }}>
        <Row>
          <Col span={3} style={{ marginTop: 15, marginLeft: 30 }}>
            <Title level={3}>{activeDisplay.name}</Title>
          </Col>
          <Col style={{ textAlign: 'right', marginRight: 5 }}>
            {createDisplayButtons(displayList)}
            <Button
              icon="reload"
              onClick={handleClickReload}
              disabled={loading}
              style={{ marginLeft: '5px' }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <StatDisplay
              statusEvents={statusEventList}
              loading={loading}
            />
          </Col>
        </Row>
        <Row>
          <Spin spinning={alertLoading}>
            <OccurringMeasurementPoint occurringMeasurementPoints={occurringMeasurementEvents} />
          </Spin>
        </Row>
      </Header>
    </>
  );
};

export default CommonHeaderDisplay;
