import React, { useState } from 'react';
import { Table } from 'antd';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import moment from 'moment';

import OMMeasurement, { TransformedDatapoint } from '../../../../../utils/Timeseries/OMMeasurement/OMMeasurement';
import { RetrievalTimeRangeInfo } from '../../../../../utils/Timeseries/OMMeasurement/TimeInterval';
// PC版で同一処理をしているため、既存コンポーネントからimportしたものを使用
import { JoinedItem, outerJoinDatapoints } from '../../../../Solar/OperationAndMaintenance/MeasurementList';

import './MeasurementTable.css';

interface MeasurementTableProps {
  measurements: OMMeasurement[];
  timeRange: RetrievalTimeRangeInfo;
  loading: boolean;
}

/**
 * 計測一覧画面での計測値表示テーブルのコンポーネント
 * @param {MeasurementTableProps} props プロパティ
 * @returns 計測値表示テーブル
 */
const MeasurementTable: React.FC<MeasurementTableProps> = (props: MeasurementTableProps) => {
  const { measurements, timeRange, loading } = props;

  const [sortInfo, setSortInfo] = useState<SorterResult<JoinedItem> | null>(null);

  // 表示対象計測値のデータポイント抽出と表示用の形式への変換
  const allDatapoints: Record<string, TransformedDatapoint[]> = {};
  measurements.forEach(({ displayName, transformedDatapoints }) => {
    allDatapoints[displayName] = transformedDatapoints;
  });

  const joinedDatapoints = Object.keys(allDatapoints).length ? outerJoinDatapoints(allDatapoints) : [];

  // 対象の計測値の名称を基に、テーブルのカラムの設定値を動的に生成
  const tableColumns: ColumnProps<JoinedItem>[] = (
    joinedDatapoints.length
      ? Object.keys(joinedDatapoints[0])
        .map((key, index) => (
          {
            title: key === 'timestamp' ? '日時' : key,
            dataIndex: `displayItem_${index}`,
            sorter: (a: JoinedItem, b: JoinedItem) => {
              const aValue = a[key];
              const bValue = b[key];
              return key === 'timestamp'
                ? (aValue as Date).valueOf() - (bValue as Date).valueOf()
                : (aValue as number) - (bValue as number);
            },
            sortOrder: sortInfo?.columnKey === `displayItem_${index}` && sortInfo.order,
            render: (_: string, item: JoinedItem) => (
              key === 'timestamp' ? moment(item[key] as Date).format(timeRange.displayFormat) : item[key]
            ),
          }
        )) : []
  ).map(
    // 日時の列（１列目）のみテーブルの左端に固定
    (col) => (col.title === '日時' ? ({ ...col, className: 'mobile-fixed-col' }) : col),
  );

  return (
    <div style={{ margin: '5% 1%' }}>
      {!!tableColumns.length && (
        <Table
          className="mobile-display-measurement-table"
          loading={loading}
          rowKey="timestamp"
          columns={tableColumns}
          dataSource={joinedDatapoints}
          onChange={(_, __, sorter) => setSortInfo(sorter)}
          pagination={false}
          scroll={{ x: 'max-content', y: 360 }}
        />
      )}
    </div>
  );
};

export default MeasurementTable;
