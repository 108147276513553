import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import './MenuParts.css';

const { SubMenu } = Menu;

/**
 * MenuIconの引数
 * @param {string} src アイコンのアドレス
 * @param {string} alt 代替テキスト
 */
interface MenuIconProps {
  src: string,
  alt: string,
}

/**
 * MenuTextの引数
 * @param {string} title メニュータイトル
 */
interface MenuTextProps {
  title: string,
}

/**
 * MenuRowの引数
 * @param {string} to componentを切り替えるアドレス
 * @param {string} icon 表示するicon path
 * @param {string} title タイトル
 */
interface MenuRowProps {
  to: string,
  icon: string,
  title: string,
}

/**
 * SubMenuRowの引数
 * @param {string} key SubMenu key
 * @param {string} icon 表示するicon path
 * @param {string} title タイトル
 * @param {JSX.Element[]} subMenuItems サブメニューの子要素
 */
interface SubMenuRowProps {
  key: string,
  icon?: string,
  title: string,
  subMenuItems: JSX.Element[],
}

/**
 * Menuアイコン作成
 * @param {MenuIconProps} props 引数
 * @returns Menu用imgタグ
 */
export const MenuIcon = (props: MenuIconProps): JSX.Element => {
  const {
    src,
    alt,
  } = props;
  return (<img src={src} alt={alt} style={{ width: '20px' }} className="anticon" />);
};

/**
 * Menuテキスト作成
 * @param {MenuTextProps} props 引数
 * @returns Menu用spanタグ
 */
export const MenuText = (props: MenuTextProps): JSX.Element => {
  const {
    title,
  } = props;
  return (<span className="menu-text">{title}</span>);
};

/**
 * Menu１行作成
 * @param {MenuRowProps} props 引数
 * @returns Menu用Linkタグ
 */
export const MenuRow = (props: MenuRowProps): JSX.Element => {
  const {
    to,
    icon,
    title,
  } = props;

  return (
    <Link to={to}>
      <div className="menu-item">
        <MenuIcon src={icon} alt={title} />
        <MenuText title={title} />
      </div>
    </Link>
  );
};

/**
 * SubMenu作成
 * @param {SubMenuRowProps} props 引数
 * @returns SubMenuタグ
 */
export const SubMenuRow = (props: SubMenuRowProps): JSX.Element => {
  const {
    key,
    icon,
    title,
    subMenuItems,
    ...others
  } = props;
  return (
    <SubMenu
      key={key}
      title={(
        <div className="menu-item">
          {icon && <MenuIcon src={icon} alt={title} />}
          <MenuText title={title} />
        </div>
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
    >
      {subMenuItems}
    </SubMenu>
  );
};

/**
 * SubMenu作成\
 * titleにdivタグが入ると2階層以下が作成できないためこちらを使用する
 * @param {SubMenuRowProps} props 引数
 * @returns SubMenuタグ
 */
export const SubMenuRowWithoutIcon = (props: SubMenuRowProps): JSX.Element => {
  const {
    key,
    title,
    subMenuItems,
    ...others
  } = props;
  return (
    <SubMenu
      key={key}
      title={title}
      className="sub-menu-row-without-icon"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
    >
      {subMenuItems}
    </SubMenu>
  );
};
