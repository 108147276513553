/* eslint-disable class-methods-use-this */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
// eslint警告未対応
import React from 'react';
import {
  Layout, Row, Col, Button, Divider, Modal,
} from 'antd';
import { Amplify, Auth, Hub } from 'aws-amplify';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { retrieveAuthResult, persistAuthResult } from '../AuthWrapper/persistance';
import {
  setTokenExpired, getTokenExpired, removeTokenExpired,
} from '../../utils/storageCommon';
import AnnouncementList from './AnnouncementList';
import { createRequestHeader, createRequestUrl } from '../../utils/AWS/ApiGateway';
import {
  EP_PATH_ANNOTATION_FILES,
  EP_PATH_LEARNING_PJ_FILES_DOWNLOAD,
  EP_PATH_ANNOTATION_FILES_DOWNLOAD,
} from '../../utils/AWS/EndpointPath';
import { sdfLogOut } from '../../utils/common';
import { refreshAuthInformation } from '../../utils/common/CacheApi';
import env from '../../sdf-env.json';
import { WARNING_TOKEN_TIMEOUT_WITH_SIGN_OUT } from '../../utils/messages';

import Spinner from '../util/Spinner';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import { MimeType } from '../../utils/File/BaseFile';
import './Portal.css';

const { Header, Content } = Layout;
const { Text } = MimeType;

/** バージョンファイルパス */
const PATH_OF_VERSION = '/version.txt';

/** バージョンファイル読み込み失敗時の表記 */
const ERROR_READ_NOTIFICATION_VERSION = 'バージョンファイルの読み込みに失敗しました';

/** OSSライセンス一覧ファイルパス */
const PATH_OF_OSS_LIST = '/oss-list.txt';

/** メンテナンス/故障情報エリアコンテナ */
const MaintenanceInformationContainer = styled.div`
  textAlign: center;
  width: 70vw;
  margin-top: 18px; // 「お知らせ」の幅が50pxであるため、合計で68pxになるように調整
  margin-bottom: 36px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 500px) , screen and (max-height: 500px) and (orientation: landscape){
    width: auto;
    margin-top: 0;
    margin-bottom: 5%;
    padding: 3%;
    padding-top: 0;
  }
`;

/** 問い合わせ先エリアコンテナ */
const ContactContainer = styled.div`
  width: 20vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  @media screen and (max-width: 500px) , screen and (max-height: 500px) and (orientation: landscape){
  width: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  }
`;
/** 問合せ先メールアドレス */
const contactAddress = 'smartdatafusion-ml@nttcom.co.jp';

/** モバイル端末width閾値 */
const MOBILE_WIDTH = 500;

/**
 * 問い合わせ先
 */
const Contact = () => {
  const contactLink = `mailto:${contactAddress}?subject=【問合せ】〇〇`;

  return (
    <>
      <p className="portal-contact-information">NTTコムウェア</p>
      <p className="portal-contact-person">SmartDataFusion担当</p>
      <a className="portal-contact-email-address" target="_blank" href={contactLink} rel="noopener noreferrer">{contactAddress}</a>
    </>
  );
};

/** Initialize Auth with AWS configurations */
const { region, cognito } = env.awsConfig;
const {
  userPoolId, userPoolClientId, domain, redirectUri,
} = cognito;
const envDomain = process.env.REACT_APP_DOMAIN ?? domain;
const envRedirectUri = process.env.REACT_APP_REDIRECT_URI ?? redirectUri;
const envUserPoolId = process.env.REACT_APP_USER_POOL_ID ?? userPoolId;
const envUserPoolClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID ?? userPoolClientId;
const cognitoConfig = {
  aws_project_region: region,
  aws_cognito_region: region,
  aws_user_pools_id: envUserPoolId,
  aws_user_pools_web_client_id: envUserPoolClientId,
  oauth: {
    domain: `${envDomain}.auth.${region}.amazoncognito.com`,
    scope: ['openid'],
    redirectSignIn: envRedirectUri,
    redirectSignOut: envRedirectUri,
    responseType: 'code',
  },
};
Amplify.configure(cognitoConfig);

/**
 * ポータル画面クラス
 */
class Portal extends React.Component {
  state = {
    /** モーダル状態を表すフラグ(true: 表示 / false: 非表示) */
    isModal: false,
    /** AWS認証状態を表すフラグ(true: ログイン / false: 未ログイン) */
    isAwsAuth: false,
    /** バージョン */
    version: '',
    /** 読み込み中フラグ(true: ログイン / false: 未ログイン) */
    isLoading: true,
  };

  /**
   * ログインボタン押下処理
   * ログイン画面へ遷移する。
   */
  handleClickLogin = async () => {
    // Cognito-AzureAD認証
    const { cognito: { provider } } = env.awsConfig;
    await Auth.federatedSignIn({ provider });
  };

  /**
   * Versionクリック時のイベントハンドラ
   */
  onVersionClick = () => {
    this.setState({ isModal: true });
  };

  /**
   * Modalキャンセル時のイベントハンドラ
   */
  onCancelModal = () => {
    this.setState({ isModal: false });
  };

  /**
   * レンダリング直後に一度だけ呼ばれる。
   * メンテナンス情報を取得し、画面に反映する。
   */
  componentDidMount() {
    this.loadVersion();
    this.configureAuthEventListener();

    const isExpiredToken = getTokenExpired();
    if (isExpiredToken) {
      Modal.warning({
        className: 'warning-sign-out-modal-content',
        title: WARNING_TOKEN_TIMEOUT_WITH_SIGN_OUT,
        centered: true,
        okText: 'OK',
      });
      removeTokenExpired();
    }

    (async () => {
      let currentAuthenticatedUser;
      try {
        currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      } catch (error) {
        // 未認証の場合、処理なし
      }

      this.setState({ isAwsAuth: currentAuthenticatedUser !== undefined, isLoading: false });
    })();
  }

  /**
   * 認証関係のイベントを設定
   */
  configureAuthEventListener = () => {
    Hub.listen('auth', async ({ payload: { event } }) => {
      switch (event) {
        case 'signIn': {
          const session = await Auth.currentSession();
          const { idToken: { jwtToken } } = session;

          const authResult = retrieveAuthResult();
          persistAuthResult({
            ...authResult,
            awsIdToken: jwtToken,
          });

          break;
        }
        case 'tokenRefresh': {
          const session = await Auth.currentSession();
          const { idToken, accessToken } = session;

          const authResult = retrieveAuthResult();
          persistAuthResult({
            ...authResult,
            awsIdToken: idToken.jwtToken,
          });

          const cache = await caches.open('sdf-preset-ap');
          const cacheAuthInformation = await cache.match('/auth-information');
          const oldAuthInformation = await cacheAuthInformation.json();

          // Cache更新
          await refreshAuthInformation({
            ...oldAuthInformation,
            accessToken: accessToken.jwtToken,
            requestHeader: createRequestHeader(),
            annotationFilesUploadUrl: createRequestUrl(EP_PATH_ANNOTATION_FILES),
            imageFilesDownloadUrl: createRequestUrl(EP_PATH_LEARNING_PJ_FILES_DOWNLOAD),
            annotationFilesDownloadUrl: createRequestUrl(EP_PATH_ANNOTATION_FILES_DOWNLOAD),
          });

          break;
        }
        case 'tokenRefresh_failure':
          sdfLogOut();
          setTokenExpired();
          break;
        default:
          break;
      }
    });
  };

  /**
   * バージョン読み込み
   */
  loadVersion = async () => {
    await fetch(PATH_OF_VERSION)
      .then((response) => response.text())
      .then((responseText) => {
        this.setState({ version: responseText });
      })
      .catch(() => {
        this.setState({ version: ERROR_READ_NOTIFICATION_VERSION });
      });
  };

  /**
   * ポータル画面をレンダリングする。
   */
  renderPortalScreen() {
    return (
      <div className="portal-container">
        <Layout className="portal-layout">
          <Header className="portal-header">
            <Row>
              <Col span={12}>
                <img className="portal-logo-text" src="./img/logotext.png" alt="SMART DATA FUSION" />
                <img className="portal-top-icon" src="./img/top_icon.png" alt="Application icon" />
              </Col>

              <Col span={12} style={{ textAlign: 'right' }}>
                <a href="https://www.nttcom.co.jp/smtech/" target="_blank" rel="noopener noreferrer">
                  <img className="portal-mvsw-logo" src="./img/MVSW_logo.jpg" alt="Company logo" />
                </a>
              </Col>
            </Row>
          </Header>

          <Content className="portal-content">

            <div className="portal-top-icon-items" style={{ textAlign: 'center' }}>
              <img
                className="portal-top-icon-main"
                src="./img/top_icon.png"
                alt="Application logo"
              />
              <div className="portal-version-button">
                <Button type="link" onClick={this.onVersionClick}>{this.state.version}</Button>
              </div>
            </div>

            <MaintenanceInformationContainer>
              <AnnouncementList />
            </MaintenanceInformationContainer>

            <div style={{ textAlign: 'center' }}>
              <Button
                className="portal-user-login-button"
                type="primary"
                onClick={this.handleClickLogin}
              >
                ログイン
              </Button>
            </div>

            <Divider />

            <ContactContainer>
              <div className="portal-contact-items">
                <h4><b>問合わせ先</b></h4>
                <Contact />
              </div>
            </ContactContainer>
            <Modal
              className="portal-license-modal"
              visible={this.state.isModal}
              centered
              closable={window.innerWidth <= MOBILE_WIDTH || window.innerHeight <= MOBILE_WIDTH}
              footer={null}
              onCancel={this.onCancelModal}
              style={{ margin: '0', padding: '0', textAlign: 'left' }}
              width={window.innerHeight <= MOBILE_WIDTH ? window.innerWidth * 0.95 : 1000}
            >
              <object aria-label="OSS一覧" data={PATH_OF_OSS_LIST} type={Text} width="100%" height="100%" />
            </Modal>
          </Content>
        </Layout>
      </div>
    );
  }

  /**
   * ログイン状態を判定し、画面をレンダリングする。
   *
   * - ログイン状態: 設備一覧画面(AuthWrapper経由)
   * - 未ログイン状態: ポータル画面
   */
  render() {
    const { isAwsAuth, isLoading } = this.state;

    return (
      isLoading
        ? <Spinner />
        : (isAwsAuth ? <AuthWrapper /> : this.renderPortalScreen())
    );
  }
}

export default Portal;
