import React from 'react';
import { Card, Statistic } from 'antd';
import moment from 'moment';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import OMStatusEvent from '../../../../../utils/Event/OMStatusEvent';

import './StatDisplay.css';

interface StatDisplayProps {
  statusEvents: OMStatusEvent[];
}

/**
 * 発電量・日射強度・気温表示ステータスのコンポーネント
 * @param {StatDisplayProps} props プロパティ
 * @returns 発電量・日射強度・気温表示ステータス
 */
const StatDisplay: React.FC<StatDisplayProps> = (props: StatDisplayProps) => {
  const { statusEvents } = props;

  /**
   * ステータス表示のタイトルを取得
   * @param {OMStatusEvent} statusEvent ステータスイベント
   * @returns {string} ステータス表示用タイトル
   */
  const getStatusTitle = (statusEvent: OMStatusEvent): string => {
    if (statusEvent.title !== '') return statusEvent.title;

    const updateTime = moment(statusEvent.startTime).format('YYYY/MM/DD HH:mm');
    const statusTitle = `${updateTime} 更新`;

    return statusTitle;
  };

  /**
   * SwiperSlide作成
   * @param {OMStatusEvent} statusEvent ステータスイベント
   * @returns {JSX.Element} SwiperSlide
   */
  const createSwiperStatusList = (statusEvent: OMStatusEvent): JSX.Element => {
    const statisticFontSize = { fontSize: '0.8em' };
    const statisticClassName = 'mobile-om-dashboard-stat-display-number';
    const {
      id,
      totalElectricEnergy,
      solarRadiationIntensity,
      temperature,
    } = statusEvent;
    return (
      <SwiperSlide key={id}>
        <Card className="mobile-om-dashboard-stat-display">
          <h4 className="mobile-om-dashboard-stat-display-title">{getStatusTitle(statusEvent)}</h4>
          <div className="mobile-om-dashboard-stat-display-content">
            <Statistic
              title="総発電量"
              value={totalElectricEnergy}
              suffix={<span style={statisticFontSize}>kWh/d</span>}
              className={statisticClassName}
            />
            <Statistic
              title="日射強度"
              value={solarRadiationIntensity}
              suffix={<span style={statisticFontSize}>kW/㎡</span>}
              className={statisticClassName}
            />
            <Statistic
              title="外気温"
              value={temperature}
              suffix={<span style={statisticFontSize}>℃</span>}
              className={statisticClassName}
            />
          </div>
        </Card>
      </SwiperSlide>
    );
  };

  return (
    <div className="mobile-om-dashboard-stat-content">
      {!!statusEvents.length && (
        <Swiper modules={[Navigation]} navigation>
          {statusEvents.map((statusEvent) => createSwiperStatusList(statusEvent))}
        </Swiper>
      )}
    </div>
  );
};

export default StatDisplay;
