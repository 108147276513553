/* eslint-disable import/no-unresolved */
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Empty,
  Modal,
  Row,
  Spin,
  message,
} from 'antd';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { EP_PATH_SOLAR_OM_FILES_DOWNLOAD } from '../../../../../utils/AWS/EndpointPath';
import { UnknownFile } from '../../../../../utils/File/BaseFile';
import OMLayoutFile from '../../../../../utils/File/OMLayoutFile';
import { ERROR_LOAD_IMAGE_FILE } from '../../../../../utils/messages';

import './LayoutModal.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export interface ImageInfo {
  key: string;
  image: string;
}

interface LayoutModalProps {
  areaAssetId: number;
  visible: boolean;
  onClose: MouseEventHandler<HTMLElement> | undefined;
}

/**
 * レイアウト表示のコンポーネント
 * @param {LayoutModalProps} props プロパティ
 * @returns レイアウト表示
 */
const LayoutModal: React.FC<LayoutModalProps> = (props: LayoutModalProps) => {
  const { areaAssetId, visible, onClose } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [layoutFileList, setLayoutFileList] = useState<JSX.Element[]>([]);

  /**
   * SwiperSlide作成
   * @param {OMLayoutFile} layoutFile 画像ファイル
   * @returns {Promise<JSX.Element>} SwiperSlide
   */
  const createSwiperLayoutList = useCallback(async (
    layoutFile: OMLayoutFile,
  ): Promise<JSX.Element> => {
    const newFile = UnknownFile.getInstance();
    newFile.id = layoutFile.id;

    const layoutUrl = await newFile.loadDownloadURLFromCDF(EP_PATH_SOLAR_OM_FILES_DOWNLOAD);
    return (
      <SwiperSlide key={layoutFile.imageSortKey}>
        <img src={layoutUrl} alt={layoutFile.name} />
      </SwiperSlide>
    );
  }, []);

  useEffect(
    () => {
      if (!areaAssetId || !loading) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        let layoutFiles: OMLayoutFile[];
        try {
          layoutFiles = await OMLayoutFile.loadLayoutFileList(areaAssetId);
          if (!layoutFiles.length) { throw new Error(); }
        } catch (e) {
          message.error(ERROR_LOAD_IMAGE_FILE);
          return;
        }

        const imgList = await Promise.all(layoutFiles.map(
          async (layoutFile) => createSwiperLayoutList(layoutFile),
        ));

        if (!canceled) {
          setLayoutFileList(imgList);
          setLoading(false);
        }
      })();

      return () => { canceled = true; };
    },
    [areaAssetId, loading, createSwiperLayoutList],
  );

  return (

    <Modal
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      className="mobile-om-dashboard-layout-display-modal"
    >
      {
        layoutFileList.length
          ? (
            <div>
              {loading ? (
                <Row>
                  <Col>
                    <Spin size="large" tip="Loading..." />
                  </Col>
                </Row>
              ) : (
                <Swiper
                  modules={[Navigation, Pagination]}
                  navigation
                  pagination={{ clickable: true }}
                >
                  {layoutFileList}
                </Swiper>
              )}
            </div>
          )
          : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
      }
    </Modal>
  );
};

export default LayoutModal;
