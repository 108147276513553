import React, { useEffect, useState } from 'react';
import { Button, Layout, Tabs } from 'antd';
import { AssetListScope } from '@cognite/sdk/dist/src';

import AlertHistoryList from './AlertHistoryList';
import CurrentAlertList from './CurrentAlertList';
import FacilityMonitor from './FacilityMonitor';
import MeasurementList from './MeasurementList';
import PcsMeasurementEventList from './PcsMeasurementEventList';
import CommonHeaderDisplay from './parts/CommonHeaderDisplay';
import LayoutModal from './parts/LayoutModal';
import OMEquipmentTree from './parts/OMEquipmentTree';
import OMEquipment from '../../../utils/Asset/OMEquipment';
import { loadAllOMEquipmentFromCDFByScope } from '../../../utils/Asset/OMEquipmentAsset';
import OMAlertEvent from '../../../utils/Event/OMAlertEvent';
import { getAreaAssetId } from '../../../utils/storageCommon';
import OMMeasurement from '../../../utils/Timeseries/OMMeasurement/OMMeasurement';

import './OperationAndMaintenance.css';

const { Content } = Layout;
const { TabPane } = Tabs;

export interface DisplayInfo {
  name: string;
  key: string;
}

export interface AlertEventInfo {
  allAlertEvents: OMAlertEvent[];
  facilityEvents: OMAlertEvent[];
  occurringMeasurementEvents: OMAlertEvent[];
}

const allDisplayList: DisplayInfo[] = [
  {
    name: '特高監視画面',
    key: '1',
  },
  {
    name: 'PCS一覧画面',
    key: '2',
  },
  {
    name: '計測一覧画面',
    key: '3',
  },
  {
    name: '警報一覧画面',
    key: '4',
  },
  {
    name: '警報履歴画面',
    key: '5',
  },
];

// 初期表示画面と切り替え先画面の一覧
const defaultDisplay = allDisplayList[0];
const defaultDisplayList = allDisplayList.filter(({ name }) => name !== defaultDisplay.name);

const defaultAlertEventInfo: AlertEventInfo = {
  allAlertEvents: [],
  facilityEvents: [],
  occurringMeasurementEvents: [],
};

/**
 * O&M特高監視コンポーネント
 * @returns O&M特高監視画面
 */
const OperationAndMaintenance: React.FC = () => {
  const areaAssetId = getAreaAssetId();
  const [activeDisplay, setActiveDisplay] = useState<DisplayInfo>(defaultDisplay);
  const [displayList, setDisplayList] = useState<DisplayInfo[]>(defaultDisplayList);
  const [selectedAsset, setSelectedAsset] = useState<OMEquipment>();
  const [loading, setLoading] = useState<boolean>(true);
  const [alertEvent, setAlertEvent] = useState<AlertEventInfo>(defaultAlertEventInfo);
  const [allLatestMeasurementList, setAllLatestMeasurementList] = useState<OMMeasurement[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [displayReload, setDisplayReload] = useState<number>(0);

  /*
   * イベントハンドラ
   */
  useEffect(
    () => {
      if (!areaAssetId) return () => { /* 何もしない */ };

      setLoading(true);
      let canceled = false;
      (async () => {
        const allAlertEvents = await OMAlertEvent.loadAllLatestAlertEvents(areaAssetId);
        const facilityEvents = allAlertEvents.filter(({ isFacilityAlert }) => isFacilityAlert);
        const occurringMeasurementEvents = facilityEvents.filter(({ severity, happening }) => severity !== 3 && happening);

        // タイムシリーズ保持アセット一覧を取得
        const scope: AssetListScope = {
          filter: {
            assetSubtreeIds: [{ id: areaAssetId }],
            metadata: { o_m_asset_level: 'ts_holder', is_facility_monitor: 'true' },
          },
        };
        const timeseriesHolderAssets = await loadAllOMEquipmentFromCDFByScope(scope);
        const targetAssetIds = timeseriesHolderAssets.map(({ id }) => id);
        const latestMeasurements = await OMMeasurement.loadAllSelectedLatestMeasurements(targetAssetIds);

        if (!canceled) {
          setAlertEvent({
            allAlertEvents,
            facilityEvents,
            occurringMeasurementEvents,
          });
          setAllLatestMeasurementList(latestMeasurements);
          setLoading(false);
        }
      })();
      return () => { canceled = true; };
    },
    [areaAssetId, displayReload],
  );

  /**
   * 表示画面切り替え時の処理
   * @param {string} selectedKey
   */
  const handleTabChange = (selectedKey: string) => {
    const selectedDisplay = allDisplayList.find(({ key }) => key === selectedKey) || allDisplayList[0];
    const nextDisplayList = allDisplayList.filter(({ name }) => name !== selectedDisplay.name);
    setActiveDisplay(selectedDisplay);
    setDisplayList(nextDisplayList);
  };

  /**
   * 特高監視ダッシュボードの設備一覧ツリークリック時の特高監視画面への切り替え用ハンドラ
   */
  const handleDisplayFacilityMonitor = () => {
    handleTabChange('1');
  };

  /**
   * PCS一覧画面のリンクから警報一覧画面への切り替え用ハンドラ
   */
  const handleDisplayAlertList = () => {
    handleTabChange('4');
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  /**
   * リロードボタン押下時のデータ再読み込み用ハンドラ
   */
  const handleReload = () => {
    setDisplayReload(displayReload + 1);
  };

  const { allAlertEvents, facilityEvents, occurringMeasurementEvents } = alertEvent;

  return (
    <Layout style={{ height: '100vh' }}>
      {/* sider */}
      <div className="facility-tree">
        <OMEquipmentTree
          parentAssetId={areaAssetId}
          title=""
          onSelect={(selected) => setSelectedAsset(selected)}
          collapsed
          isExpandedRoot
          facilityAlertEvents={occurringMeasurementEvents ?? []}
          displayReload={displayReload}
          handleDisplayFacilityMonitor={handleDisplayFacilityMonitor}
        />
      </div>
      <Layout>
        <div className="header">
          <CommonHeaderDisplay
            areaAssetId={areaAssetId}
            displayList={displayList}
            activeDisplay={activeDisplay}
            handleTabChange={handleTabChange}
            alertLoading={loading}
            displayReload={displayReload}
            occurringMeasurementEvents={occurringMeasurementEvents}
            handleClickReload={handleReload}
          />
        </div>

        <Content className="main" style={{ padding: '0 50px' }}>
          <Button className="om-dashboard-layout-display-button" type="primary" onClick={handleOpenModal}>
            レイアウト表示
          </Button>
          <LayoutModal
            areaAssetId={areaAssetId}
            visible={isModalVisible}
            onClose={handleCloseModal}
          />
          <Tabs
            className="om-dashboard-tab-items"
            animated={false}
            activeKey={activeDisplay.key}
            onChange={handleTabChange}
          >
            {/* TabPaneにtabプロパティ（タブの表示名）を明記しないと画面遷移時にコンソールに警告が表示されるので、空文字で指定（タブそのものは表示しないため） */}
            <TabPane tab="" key="1">
              <FacilityMonitor
                facilityAlertEvents={facilityEvents ?? []}
                allLatestMeasurements={allLatestMeasurementList}
                targetAsset={selectedAsset}
                loading={loading}
              />
            </TabPane>
            <TabPane tab="" key="2">
              {/* PCS一覧画面のリンクから警報一覧画面への切り替え用ハンドラ */}
              <PcsMeasurementEventList
                areaAssetId={areaAssetId}
                handleDisplayAlert={handleDisplayAlertList}
                displayReload={displayReload}
              />
            </TabPane>
            <TabPane tab="" key="3">
              <MeasurementList areaAssetId={areaAssetId} />
            </TabPane>
            <TabPane tab="" key="4">
              <CurrentAlertList allAlertEvents={allAlertEvents ?? []} loading={loading} />
            </TabPane>
            <TabPane tab="" key="5">
              <AlertHistoryList areaAssetId={areaAssetId} displayReload={displayReload} />
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </Layout>
  );
};

export default OperationAndMaintenance;
