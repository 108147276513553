import React from 'react';
import { Collapse, List } from 'antd';

import OMAlertEvent from '../../../../../utils/Event/OMAlertEvent';

import './OccurringMeasurementPoint.css';

const { Panel } = Collapse;

interface OccurringMeasurementPointProps {
  occurringMeasurementPoints: OMAlertEvent[];
}

/**
 * 警報発生中の計測ポイントコンポーネント
 * @param {OccurringMeasurementPointProps} props
 * @returns 警報発生中の計測ポイント
 */
const OccurringMeasurementPoint: React.FC<OccurringMeasurementPointProps> = (props: OccurringMeasurementPointProps) => {
  const { occurringMeasurementPoints } = props;

  return (
    <div className="mobile-om-dashboard-alert-occurrence-content">
      {!!occurringMeasurementPoints.length && (
        <Collapse defaultActiveKey={[1]}>
          <Panel
            key="1"
            header={(
              <div className="mobile-om-dashboard-alert-occurrence-bar">
                <b className="mobile-om-dashboard-alert-occurrence-text">警報発生中！</b>
              </div>
            )}
          >
            <div className="mobile-om-dashboard-alert-occurring-items">
              <List
                dataSource={occurringMeasurementPoints}
                renderItem={(item) => (
                  <List.Item>
                    <span className={`mobile-om-dashboard-alert-occurrence-${item.severity === 1 ? 'red' : 'yellow'}-style-circle`}>
                      ●
                    </span>
                    {item.monitorName}
                  </List.Item>
                )}
              />
            </div>
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default OccurringMeasurementPoint;
