import React from 'react';
import { Collapse, Descriptions } from 'antd';

import OMMeasurement from '../../../../../utils/Timeseries/OMMeasurement/OMMeasurement';

import './MeasurementValue.css';

const { Panel } = Collapse;

type DisplayItem = {
  displayName: string,
  latestValue?: number,
};

interface MeasurementValueProps {
  latestMeasurements: OMMeasurement[];
}

/**
 * 計測ポイント表示のコンポーネント
 * @param {MeasurementValueProps} props プロパティ
 * @returns 計測値
 */
const MeasurementValue: React.FC<MeasurementValueProps> = (props: MeasurementValueProps) => {
  const { latestMeasurements } = props;

  // 画面表示用に表示名称と値を取得する
  const displayItems: DisplayItem[] = latestMeasurements.map(
    ({ displayName, datapoints }) => (
      { displayName, latestValue: datapoints[0]?.value }
    ),
  );

  /**
   * 計測値描画
   * @param {DisplayItem} displayItem 計測値情報
   * @returns {JSX.Element} Descriptions.Item（計測値）
   */
  const getDescriptionsItems = (displayItem: DisplayItem): JSX.Element => {
    const { displayName, latestValue } = displayItem;

    return (
      <Descriptions.Item key={displayName} label={displayName}>
        {latestValue}
      </Descriptions.Item>
    );
  };

  return (
    <div className="mobile-measurement-value-content">
      {!displayItems.length ? (
        <div className="mobile-measurement-value-title">計測値</div>
      ) : (
        <Collapse expandIconPosition="right" defaultActiveKey="1">
          <Panel
            className="mobile-measurement-value-body"
            header="計測値"
            key="1"
          >
            <Descriptions bordered column={1}>
              {displayItems.map((item) => getDescriptionsItems(item))}
            </Descriptions>
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default MeasurementValue;
