import React, { useEffect, useState } from 'react';
import { Layout, Tabs } from 'antd';
import { AssetListScope } from '@cognite/sdk/dist/src';

import FacilityMonitor from './FacilityMonitor';
import MeasurementList from './MeasurementList';
import CommonHeaderDisplay from './parts/CommonHeaderDisplay';
import { AlertEventInfo } from '../../../Solar/OperationAndMaintenance/OperationAndMaintenance';
import { loadAllOMEquipmentFromCDFByScope } from '../../../../utils/Asset/OMEquipmentAsset';
import OMAlertEvent from '../../../../utils/Event/OMAlertEvent';
import OMStatusEvent from '../../../../utils/Event/OMStatusEvent';
import { getAreaAssetId } from '../../../../utils/storageCommon';
import OMMeasurement from '../../../../utils/Timeseries/OMMeasurement/OMMeasurement';

import './OperationAndMaintenance.css';

const { Content } = Layout;
const { TabPane } = Tabs;

export interface DisplayInfo {
  name: string;
  key: string;
}

const allDisplayList: DisplayInfo[] = [
  {
    name: '特高監視',
    key: '1',
  },
  {
    name: '計測一覧',
    key: '2',
  },
];

// 初期表示画面と切り替え先画面の一覧
const defaultDisplay = allDisplayList[0];

const defaultAlertEventInfo: AlertEventInfo = {
  allAlertEvents: [],
  facilityEvents: [],
  occurringMeasurementEvents: [],
};

/**
 * O&M特高監視コンポーネント
 * @returns O&M特高監視画面
 */
const OperationAndMaintenance: React.FC = () => {
  const areaAssetId = getAreaAssetId();
  const [activeDisplay, setActiveDisplay] = useState<DisplayInfo>(defaultDisplay);
  const [loading, setLoading] = useState<boolean>(true);
  const [statusEventList, setStatusEventList] = useState<OMStatusEvent[]>([]);
  const [alertEvent, setAlertEvent] = useState<AlertEventInfo>(defaultAlertEventInfo);
  const [allLatestMeasurementList, setAllLatestMeasurementList] = useState<OMMeasurement[]>([]);
  const [displayReload, setDisplayReload] = useState<number>(0);

  useEffect(
    () => {
      if (!areaAssetId) return () => { /* 何もしない */ };

      setLoading(true);
      let canceled = false;
      (async () => {
        const statusEvents = await OMStatusEvent.loadStatusEvents(areaAssetId);
        const allAlertEvents = await OMAlertEvent.loadAllLatestAlertEvents(areaAssetId);
        const facilityEvents = allAlertEvents.filter(({ isFacilityAlert }) => isFacilityAlert);
        const occurringMeasurementEvents = facilityEvents.filter(({ severity, happening }) => severity !== 3 && happening);

        // タイムシリーズ保持アセット一覧を取得
        const scope: AssetListScope = {
          filter: {
            assetSubtreeIds: [{ id: areaAssetId }],
            metadata: { o_m_asset_level: 'ts_holder', is_facility_monitor: 'true' },
          },
        };
        const timeseriesHolderAssets = await loadAllOMEquipmentFromCDFByScope(scope);
        const targetAssetIds = timeseriesHolderAssets.map(({ id }) => id);
        const latestMeasurements = await OMMeasurement.loadAllSelectedLatestMeasurements(targetAssetIds);

        if (!canceled) {
          setStatusEventList(statusEvents);
          setAlertEvent({
            allAlertEvents,
            facilityEvents,
            occurringMeasurementEvents,
          });
          setAllLatestMeasurementList(latestMeasurements);
          setLoading(false);
        }
      })();
      return () => { canceled = true; };
    },
    [areaAssetId, displayReload],
  );

  /**
   * 表示画面切り替え時の処理
   * @param {string} selectedKey
   */
  const handleTabChange = (selectedKey: string) => {
    const selectedDisplay = allDisplayList.find(({ key }) => key === selectedKey) || allDisplayList[0];
    setActiveDisplay(selectedDisplay);
  };

  /**
   * リロードボタン押下時のデータ再読み込み用ハンドラ
   */
  const handleReload = () => {
    setDisplayReload(displayReload + 1);
  };

  const { occurringMeasurementEvents } = alertEvent;
  return (
    <Layout style={{ height: '100%' }}>
      <CommonHeaderDisplay
        areaAssetId={areaAssetId}
        displayList={allDisplayList}
        handleTabChange={handleTabChange}
        statusEvents={statusEventList}
        occurringMeasurementEvents={occurringMeasurementEvents}
        loading={loading}
        handleClickReload={handleReload}
      />
      <Content style={{ width: '98%', margin: '0 auto' }}>
        <Tabs
          className="mobile-om-dashboard-tab-items"
          animated={false}
          activeKey={activeDisplay.key}
          onChange={handleTabChange}
        >
          {/* TabPaneにtabプロパティ（タブの表示名）を明記しないと画面遷移時にコンソールに警告が表示されるので、空文字で指定（タブそのものは表示しないため） */}
          <TabPane tab="" key="1">
            <FacilityMonitor
              areaAssetId={areaAssetId}
              alertEvent={alertEvent}
              allLatestMeasurements={allLatestMeasurementList}
              loading={loading}
              displayReload={displayReload}
            />
          </TabPane>
          <TabPane tab="" key="2">
            <MeasurementList areaAssetId={areaAssetId} />
          </TabPane>
        </Tabs>
      </Content>
    </Layout>
  );
};

export default OperationAndMaintenance;
