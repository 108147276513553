import React from 'react';
import {
  Card,
  Col,
  List,
  Tooltip,
} from 'antd';

import OMMeasurement from '../../../../utils/Timeseries/OMMeasurement/OMMeasurement';

import './MeasurementValue.css';

type DisplayItem = {
  displayName: string,
  latestValue?: number,
};

interface MeasurementValueProps {
  latestMeasurements: OMMeasurement[];
}

const NAME_LENGTH_LIMIT = 15;

/**
 * 計測ポイント表示のコンポーネント
 * @param {MeasurementValueProps} props プロパティ
 * @returns 計測値
 */
const MeasurementValue: React.FC<MeasurementValueProps> = (props: MeasurementValueProps) => {
  const { latestMeasurements } = props;

  // 画面表示用に表示名称と値を取得する
  const displayItem: DisplayItem[] = latestMeasurements.map(
    ({ displayName, datapoints }) => (
      { displayName, latestValue: datapoints[0]?.value }
    ),
  );

  /**
   * 計測値名描画
   * @param {string} name
   * @returns {JSX.Element} 計測値名称
   */
  const renderDisplayName = (name: string): JSX.Element => {
    const measurementName = (name.length > NAME_LENGTH_LIMIT)
      ? `${name.substring(0, NAME_LENGTH_LIMIT)}...`
      : name;

    return <Tooltip title={name}>{measurementName}</Tooltip>;
  };

  return (
    <div style={{ marginTop: '1%' }}>
      <div className="om-dashboard-measurement-value-title">計測値</div>
      {!!latestMeasurements.length && (
        <Col span={2} className="om-dashboard-measurement-value-list">
          <List
            grid={{
              gutter: 0,
              column: 6,
            }}
            dataSource={displayItem}
            renderItem={(item) => (
              <List.Item>
                <Card
                  className="om-dashboard-measurement-value-card"
                  title={renderDisplayName(item.displayName)}
                >
                  {item.latestValue}
                </Card>
              </List.Item>
            )}
          />
        </Col>
      )}
    </div>
  );
};

export default MeasurementValue;
