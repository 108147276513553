import { AssetListScope, CogniteEvent, EventFilter } from '@cognite/sdk';

import { loadAllSolarSiteFromCDFByScope } from '../Asset/SolarSiteAsset';
import { EP_PATH_SOLAR_EGS_EVENTS_LIST } from '../AWS/EndpointPath';
import { getAllEvents } from '../dataAccess';

class EmailNotificationTargetSiteInfo {
  /**
   * メンバ変数
   */
  information: CogniteEvent;

  locationInformation: CogniteEvent;

  get externalId(): string {
    if (!this.information.metadata || !this.information.externalId) return '';
    return this.information.externalId?.replace('_information', '');
  }

  get siteName(): string {
    if (!this.information.metadata || !this.information.metadata.site_name_jp) return '';
    return this.information.metadata.site_name_jp;
  }

  get businessName(): string {
    if (!this.locationInformation.metadata || !this.locationInformation.metadata.business_classification_jp) return '';
    return this.locationInformation.metadata.business_classification_jp;
  }

  get category1(): string {
    if (!this.locationInformation.metadata || !this.locationInformation.metadata.category1_jp) return '';
    return this.locationInformation.metadata.category1_jp;
  }

  get category2(): string {
    if (!this.locationInformation.metadata || !this.locationInformation.metadata.business_name_jp) return '';
    return this.locationInformation.metadata.business_name_jp;
  }

  get startDate(): string {
    if (!this.information.metadata || !this.information.metadata['運転開始年月']) return '';
    return this.information.metadata['運転開始年月'];
  }

  get connectCapacity(): number {
    if (!this.information.metadata || !this.information.metadata['連系容量（kW）']) return NaN;
    return Number(this.information.metadata['連系容量（kW）']);
  }

  get panelCapacity(): number {
    if (!this.information.metadata || !this.information.metadata['パネル容量（kW）']) return NaN;
    return Number(this.information.metadata['パネル容量（kW）']);
  }

  /**
   * コンストラクタ
   * @param {CogniteEvent} information informationイベント
   * @param {CogniteEvent} locationInformation location_informationイベント
   */
  constructor(information: CogniteEvent, locationInformation: CogniteEvent) {
    this.information = information;
    this.locationInformation = locationInformation;
  }

  /**
   * 「assetType: 'sdfasset'」に該当するサイトのinformationイベント、location_informationイベントをCDFから取得する。
   * @returns {Promise<EmailNotificationTargetSiteInfo[]>} 取得結果
   */
  static async loadSiteInformationList(): Promise<EmailNotificationTargetSiteInfo[]> {
    // 運用上metadata.assetType = 'sdfasset'のデータは1件のみ
    const scopeSiteParent: AssetListScope = { filter: { metadata: { assetType: 'sdfasset' } } };
    const [solarRootAssetParent] = await loadAllSolarSiteFromCDFByScope(scopeSiteParent);

    // サイト配下に存在するinformationイベントを全件取得
    const informationEventFilter: EventFilter = {
      assetSubtreeIds: [
        {
          id: solarRootAssetParent.id,
        },
      ],
      type: 'information',
    };

    const informationEvents = await getAllEvents(
      EP_PATH_SOLAR_EGS_EVENTS_LIST,
      informationEventFilter,
    ) as CogniteEvent[];

    // サイト配下に存在するlocation_informationイベントを全件取得
    const locationInformationEventFilter: EventFilter = {
      assetSubtreeIds: [
        {
          id: solarRootAssetParent.id,
        },
      ],
      type: 'location_information',
    };

    const locationInformationEvents = await getAllEvents(
      EP_PATH_SOLAR_EGS_EVENTS_LIST,
      locationInformationEventFilter,
    ) as CogniteEvent[];

    // 「発電・死活監視メール設定」画面表示用にinformationイベントとlocation_informationの内容を突合
    const eventList = informationEvents.map((informationEvent) => {
      const location = locationInformationEvents.find((locationEvent) => (
        locationEvent.assetIds?.some((assetId) => informationEvent.assetIds?.includes(assetId))
      ));

      const locationEvent = location ?? {} as CogniteEvent;
      return new EmailNotificationTargetSiteInfo(informationEvent, locationEvent);
    });

    return [...eventList].sort((a, b) => {
      const first = [a.siteName, b.siteName].sort()[0];
      return first === a.siteName ? -1 : 1;
    });
  }
}

export default EmailNotificationTargetSiteInfo;
