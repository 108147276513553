/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import React from 'react';
import {
  Table, Icon, Button, Modal, Spin,
} from 'antd';
import { MimeType } from '../../utils/File/BaseFile';
import { ERROR_READ_NOTIFICATION } from '../../utils/messages';
import './AnnouncementList.css';

const PATH_OF_ANNOUNCEMENT_LIST = '/announcement/announcements.json';

const { Html } = MimeType;

/**
 * メンテナンス情報テーブルのスクロール領域高さ(1行=54px(リンク有り65px))
 */
const MAINTENANCE_INFORMATION_SCROLL_Y = 270;

const ERROR_READ_NOTIFICATION_JSON = [{
  key: 1,
  type: 'failure',
  startTime: '－',
  endTime: '－',
  title: ERROR_READ_NOTIFICATION,
}];

/** モバイル端末width閾値 */
const MOBILE_WIDTH = 500;

/**
 * お知らせ表示
 */
const AnnouncementList = () => {
  /**
   * 変数/定数定義
   */
  const [announcements, setAnnouncements] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [target, setTarget] = React.useState();
  const [InformationOfDetail, setInformationOfDetail] = React.useState();
  const [modalIsVisible, setModalIsVisible] = React.useState(false);

  /**
   * イベントハンドラ
   */
  React.useEffect(
    () => {
      loadAnnouncements();
    },
    [],
  );

  /**
   * 内容のリンククリック時のイベントハンドラ
   * @param {array} announcement クリックしたお知らせ
   */
  const onLinkClick = (announcement) => {
    setTarget(announcement);
    setInformationOfDetail(
      <object
        className="announcement-detail"
        aria-label="詳細情報"
        data={announcement.detailPath}
        type={Html}
        width="100%"
        height="400px"
      />,
    );
    setModalIsVisible(true);
  };

  /**
   * Modalキャンセル時のイベントハンドラ
   */
  const onCancelModal = () => {
    setModalIsVisible(false);
  };

  /**
   * お知らせ一覧の読み込み
   */
  async function loadAnnouncements() {
    setLoading(true);
    await fetch(PATH_OF_ANNOUNCEMENT_LIST)
      .then((response) => response.json())
      .then((responseJson) => {
        setAnnouncements(responseJson);
      })
      .catch(() => {
        setAnnouncements(ERROR_READ_NOTIFICATION_JSON);
      });
    setLoading(false);
  }

  /**
   * 画面描画
   */
  const columns = [
    {
      title: '種別',
      className: 'announcement-type',
      dataIndex: 'type',
      render: (_text, announcement, _index) => {
        let type = null;
        switch (announcement.type) {
          case 'maintenance':
            type = <span>メンテナンス</span>;
            break;
          case 'failure':
            type = <span>故障</span>;
            break;
          case 'update':
            type = <span>アップデート</span>;
            break;
          default:
            type = <span>その他</span>;
            break;
        }
        return type;
      },
    },
    {
      title: '日時',
      className: 'announcement-date',
      dataIndex: 'dateTime',
      render: (_text, announcement, _index) => (
        <span>
          {
            announcement.startTime !== announcement.endTime
              ? `${announcement.startTime}～${announcement.endTime}`
              : `${announcement.startTime}`
          }
        </span>
      ),
    },
    {
      title: '内容',
      dataIndex: 'title',
      render: (_text, announcement, _index) => (
        announcement.detailPath
          ? (
            <div className="announcement-link-item">
              <span>
                <Button
                  className="announcement-link-button"
                  type="link"
                  onClick={onLinkClick.bind(this, announcement)}
                >
                  {announcement.title}
                </Button>
              </span>
            </div>
          )
          : <span className="announcement-not-link-item">{announcement.title}</span>
      ),
    },
  ];

  return (
    <div className="announcement-container">
      <div className="announcement-information-reload-items">
        <Icon type="info-circle" />
        &nbsp;
        <span>お知らせ</span>
        <Button
          className="announcement-reload-button"
          type="link"
          icon="reload"
          size="large"
          onClick={loadAnnouncements.bind(this)}
        />
      </div>
      <Table
        className="announcement-table"
        loading={loading}
        columns={columns}
        dataSource={announcements}
        pagination={false}
        scroll={{ y: MAINTENANCE_INFORMATION_SCROLL_Y }}
      />
      <Modal
        className="release-note-modal"
        visible={modalIsVisible}
        title={target ? target.title : ''}
        footer={null}
        centered
        destroyOnClose
        onCancel={onCancelModal.bind(this)}
        width={window.innerHeight <= MOBILE_WIDTH ? window.innerWidth * 0.95 : 1200}
      >
        <Spin spinning={loading}>
          {InformationOfDetail}
        </Spin>
      </Modal>
    </div>
  );
};

export default AnnouncementList;
