import React, { useState } from 'react';
import { Spin } from 'antd';

import FacilityList from './parts/FacilityList';
import MeasurementPoint from './parts/MeasurementPoint';
import MeasurementValue from './parts/MeasurementValue';
import OMEquipmentTree from './parts/OMEquipmentTree';
import { AlertEventInfo } from '../../../Solar/OperationAndMaintenance/OperationAndMaintenance';
import OMEquipment from '../../../../utils/Asset/OMEquipment';
import OMAlertEvent from '../../../../utils/Event/OMAlertEvent';
import OMMeasurement from '../../../../utils/Timeseries/OMMeasurement/OMMeasurement';

import './FacilityMonitor.css';

type FacilityAlertInfo = {
  facilityPoints: OMAlertEvent[];
  measurementPoints: OMAlertEvent[];
};

interface FacilityMonitorProps {
  areaAssetId?: number;
  alertEvent: AlertEventInfo;
  allLatestMeasurements: OMMeasurement[];
  loading: boolean;
  displayReload: number;
}

/**
 * 特高監視画面のコンポーネント
 * @param {FacilityMonitorProps} props プロパティ
 * @returns 特高監視画面
 */
const FacilityMonitor: React.FC<FacilityMonitorProps> = (props: FacilityMonitorProps) => {
  const {
    areaAssetId,
    alertEvent,
    allLatestMeasurements,
    loading,
    displayReload,
  } = props;
  const [selectedAsset, setSelectedAsset] = useState<OMEquipment>();

  /**
   * 選択しているアセットがフロア（親フロアを除く）場合、フロアに属する計測ポイント一覧と対象設備一覧を取得して返却
   * @param {OMAlertEvent[]} alertEvents 警報イベント一覧
   * @param {OMEquipment | undefined} floor 選択中のアセット
   * @returns {FacilityAlertInfo} 対象フロアに属する計測ポイント一覧・対象設備一覧
   */
  const filterMeasurementPoints = (alertEvents: OMAlertEvent[], floor?: OMEquipment): FacilityAlertInfo => {
    if (!floor || floor.hasChildFloors) return { facilityPoints: [], measurementPoints: [] };

    const targetAlerts = alertEvents.filter(({ assetIds }) => assetIds && assetIds.includes(floor.id));

    const facilityPoints = targetAlerts.filter(({ severity }) => severity === 3);
    const measurementPoints = targetAlerts.filter(({ severity }) => severity !== 3);

    return { facilityPoints, measurementPoints };
  };

  /**
   * 対象フロアの計測値のみ取得して返却
   * @param {OMMeasurement[]} latestMeasurements 最新の計測値一覧
   * @param {OMEquipment | undefined} floor 選択中のアセット
   * @returns {OMMeasurement[]} 対象フロアに属する計測値一覧
   */
  const filterMeasurementValues = (latestMeasurements: OMMeasurement[], floor?: OMEquipment): OMMeasurement[] => {
    if (!floor || floor.hasChildFloors) return [];

    return latestMeasurements.filter(({ floorName }) => floorName === floor.name);
  };

  const { facilityEvents, occurringMeasurementEvents } = alertEvent;
  const { measurementPoints, facilityPoints } = filterMeasurementPoints(facilityEvents, selectedAsset);
  const targetLatestMeasurements = filterMeasurementValues(allLatestMeasurements, selectedAsset);

  return (
    <>
      <OMEquipmentTree
        alertLoading={loading}
        occurringMeasurementEvents={occurringMeasurementEvents}
        areaAssetId={areaAssetId}
        onSelect={(selected) => setSelectedAsset(selected)}
        displayReload={displayReload}
      />
      {selectedAsset && (
        <Spin spinning={loading}>
          {(selectedAsset.assetLevel === 'floor' && !selectedAsset.hasChildFloors) && (
            <div className="mobile-facility-monitor-element-content">
              <MeasurementPoint targetPoints={measurementPoints} />
              <MeasurementValue latestMeasurements={targetLatestMeasurements} />
              <FacilityList targetFacilities={facilityPoints} />
            </div>
          )}
        </Spin>
      )}
    </>
  );
};

export default FacilityMonitor;
